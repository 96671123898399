import React, { useEffect, useState } from "react";
import { UncontrolledTooltip, Col, Row } from "reactstrap";
import {
  defaultTableOption,
  attendanceStatuses,
  attendanceStatusSelectStyles,
} from "utility/constants";
import CustomSelect from "components/customs/dropdown";
import CustomDataTable from "components/customs/table";
import DeclineModal from "components/customs/declineModal";
import { Trash2 } from "react-feather";
import {
  hasPermission,
  getFormattedDateString,
  formatTime,
  Toast,
} from "utility/helpers";
import { useLocation } from "react-router";
import {
  genericGet,
  genericPut,
  genericDelete,
} from "services/services.general";
import Swal from "sweetalert2";

import {
  MainContainer,
  Container,
  BreadCrumbSection,
  HeaderTextSection,
  TableSection,
} from "../styled";

const EventDetail = () => {
  const location = useLocation();
  const row = location.state.event;
  const [tableOption, setTableOption] = React.useState(defaultTableOption);

  const [isDeclineModalShown, setIsDeclineModalShown] = useState(false);

  const [congEvent, setCongEvent] = useState(null);
  const [eventTitle, setEventName] = useState(null);
  const [eveId, setEventId] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  const toggleDeclineModal = () => setIsDeclineModalShown(!isDeclineModalShown);

  const fetchEventInvitees = async () => {
    await genericGet({
      url: `/events/${row.id}/participants`,
      callback: (data) => {
        setTableOption({
          ...tableOption,
          data: data.rows,
          totalRows: data.count,
        });

        setRowCount(data.count);
      },
    });
  };

  const getStatusOptions = () => {
    const options = attendanceStatuses;

    return options;
  };

  const handleCloseModal = () => {
    toggleDeclineModal();
  };

  const handleSaveStatusChange = (data) => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    genericPut({
      url: `/eventsparticipant/${data.id}`,
      formData: {
        eventParticipantStatus: data?.eventParticipantStatus,
      },
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          fetchEventInvitees();
        }
      },
    });
  };

  const getStatusOptionsPosition = (index) => {
    if (index >= rowCount - 4) {
      return "top";
    }

    return "bottom";
  };

  const handleStatusChange = async (index, option) => {
    const newData = [...tableOption.data];
    const oldValue = newData[index].eventParticipantStatus;
    const newValue = option.value;

    const oldStatus = attendanceStatuses.find(
      (status) => status.value === newData[index].eventParticipantStatus
    ).label;

    if (oldValue.toLowerCase() !== newValue.toLowerCase()) {
      try {
        if (newValue === "Declined") {
          newData[index] = {
            ...newData[index], // Keep all other properties
            eventParticipantStatus: newValue,
          };
          setEventName(newData[index].eventName);
          setEventId(newData[index].id);
          setCongEvent(newData[index]);
          setIsDeclineModalShown(true);
        } else {
          const result = await Swal.fire({
            title: "Warning",
            // eslint-disable-next-line max-len
            text: `Are you sure you want to change the status from ${oldStatus} to ${option.label}?`,
            icon: "warning",
            confirmButtonText: "OK",
            showCancelButton: true,
          });

          if (result.isConfirmed) {
            newData[index] = {
              ...newData[index],
              eventParticipantStatus: newValue,
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });

            handleSaveStatusChange(newData[index], index);
          } else {
            newData[index] = {
              ...newData[index],
              eventParticipantStatus: oldValue,
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const handleDeleteAction = (rowData) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to delete participant ${rowData?.Congressman.fullName}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        genericDelete({
          url: `/eventsparticipant/${rowData.id}`,
          callback: ({ isSuccess }) => {
            setTableOption({
              ...tableOption,
              isLoading: false,
            });

            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully removed event participant.",
              });
              fetchEventInvitees();
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "Member Name",
      selector: (Congressman) => Congressman?.fullName,
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ Congressman }) => (
        <p className="mb-0">{Congressman?.fullName}</p>
      ),
    },
    {
      name: "Date Period",
      selector: ({ Event }) => Event?.eventStartDate,
      identifier: "datePeriod",
      grow: 1,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ Event }) => (
        <p className="mb-0 wrap-text">
          {getFormattedDateString(Event.eventStartDate, "MMM dd, yyyy")}
          {" - "}
          {getFormattedDateString(Event.eventEndDate, "MMM dd, yyyy")}
        </p>
      ),
    },
    {
      name: "Time",
      selector: ({ Event }) => Event?.eventStartTime,
      identifier: "time",
      grow: 1,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ Event }) => (
        <p className="mb-0 wrap-text">
          {formatTime(Event.eventStartTime)}
          {" - "}
          {formatTime(Event.eventEndTime)}
        </p>
      ),
      width: "170px",
    },
    {
      name: "Mode",
      selector: (Event) => Event?.mode,
      sortable: true,
      // minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ Event }) => <p className="mb-0">{Event.mode}</p>,
    },
    {
      name: "Status",
      selector: (eventParticipantStatus) => eventParticipantStatus,
      grow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventParticipantStatus }, index) => (
        <CustomSelect
          isSearchable={false}
          onChange={(option) => {
            handleStatusChange(index, option);
          }}
          value={attendanceStatuses.find(
            (stat) => stat?.value === eventParticipantStatus
          )}
          options={getStatusOptions()}
          styles={attendanceStatusSelectStyles}
          classNamePrefix="select"
          menuPlacement={getStatusOptionsPosition(index)}
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(option) => {
            return option?.displayLabel;
          }}
          menuPortalTarget={document.body}
        />
      ),
    },
    {
      name: "Actions",
      selector: (rowData) => rowData?.id,
      sortable: false,
      grow: 1,
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (rowData, index) =>
        hasPermission("event-delete") && (
          <>
            <Trash2
              size={16}
              id={`delete-row--${index}`}
              onClick={() => handleDeleteAction(rowData)}
              color="#4252C9"
            />
            <UncontrolledTooltip
              placement="right"
              target={`delete-row--${index}`}
            >
              {"Delete"}
            </UncontrolledTooltip>
          </>
        ),
    },
  ];

  useEffect(() => {
    fetchEventInvitees();
  }, []);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection> </BreadCrumbSection>
        <HeaderTextSection>
          <h2 className="text-danger">{"Events"}</h2>
        </HeaderTextSection>

        <TableSection>
          <Col
            xs="12"
            className="d-flex justify-content-between align-items-center"
          >
            <Row>
              <Col
                xs="12"
                md="12"
              >
                <h1 style={{ fontSize: 48 }}>{row.eventName}</h1>
              </Col>
              <Col
                xs="12"
                md="12"
              >
                <p style={{ fontSize: 18 }}>
                  {getFormattedDateString(row.eventStartDate, "MMM dd, yyyy")}{" "}
                  {formatTime(row.eventStartTime)}
                  {" | "}
                  {row.eventVenue}
                </p>
              </Col>
              <p
                style={{ fontSize: 18 }}
                className="mt-4 mb-5"
              >
                {row.eventDescription}
              </p>
            </Row>
          </Col>
          <Row>
            <Col xs="10">
              <h3 className="d-inline">{"Attendace Log"} </h3>
              <span style={{ fontSize: 16 }}>
                {"("}
                {row.numberOfConfirmed}
                {" confirmed, "}
                {row.totalParticipants}
                {" attendees)"}
              </span>
              <p>{"All members of this program that has been invited"}</p>
            </Col>
          </Row>
          <CustomDataTable
            columns={columns}
            data={tableOption.data}
            noHeader
            paginationPerPage={tableOption.currentRowsPerPage}
            paginationServer
            paginationTotalRows={tableOption.totalRows}
            height="calc(100vh - 200px)"
          />
        </TableSection>
      </Container>
      <DeclineModal
        isOpen={isDeclineModalShown}
        event={congEvent}
        handleClose={handleCloseModal}
        fetchDataTable={fetchEventInvitees}
        eventName={eventTitle}
        eventId={eveId}
        view="events"
      />
    </MainContainer>
  );
};

export default EventDetail;
