import ImgContainer from "components/common/img";
import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { Tooltip } from "react-tooltip";
import CustomSelect from "components/customs/dropdown";
import CustomDataTable from "components/customs/table";
import { getUserDetails } from "config";
import moment from "moment";
import { getFormattedDateString, formatTime } from "utility/helpers";
import { Eye, Info } from "react-feather";
import CommentsModal from "components/customs/comments-modal";
import DeclineModal from "components/customs/declineModal";
import { genericGet, genericPut } from "services/services.general";
import {
  defaultTableOption,
  congRoles,
  attendanceStatuses,
  attendanceStatusSelectStyles,
} from "utility/constants";

import Swal from "sweetalert2";

import {
  CommentsContainer,
  ViewCommentBtn,
  AddCommentBtn,
  TableSection,
} from "./styled";

const Main = () => {
  const { id, congID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const repDetails = location?.state.repDetails || null;
  const congLogs = location?.state.congLogs || null;

  const [congDetails, setCongDetails] = useState({});
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [hasAccess, setHasAccess] = useState(false);
  const [logs, setLogs] = useState([]);

  const [isModalShown, setIsModalShown] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [comments, setComments] = useState([]);
  const [isDeclineModalShown, setIsDeclineModalShown] = useState(false);

  const [congEvent, setCongEvent] = useState(null);
  const [eventTitle, setEventName] = useState(null);
  const [eveId, setEventId] = useState(0);

  const [rowCount, setRowCount] = useState(0);

  const role = getUserDetails()?.type;

  const isRoleCongressman = congRoles.includes(role);

  const toggleModal = () => setIsModalShown(!isModalShown);
  const toggleDeclineModal = () => setIsDeclineModalShown(!isDeclineModalShown);

  const getStatusOptions = (status) => {
    let options = attendanceStatuses;

    if (!role.includes("Director", "Editor")) {
      if (
        status === "Invited" ||
        status === "Declined" ||
        status === "Confirmed"
      ) {
        options = options?.filter(
          (option) =>
            option.value === "Confirmed" || option.value === "Declined"
        );
      }
    }

    return options;
  };

  const handleCloseModal = () => {
    toggleDeclineModal();
  };

  const fetchCongEvents = () => {
    genericGet({
      url: `congressman/${congID}/events`,
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          data: data.rows,
          totalRows: data.count,
        });

        setRowCount(data.count);
      },
    });
  };

  const fetchComments = (eventId) => {
    genericGet({
      url: `/events/${eventId}/comments`,
      callback: (data) => {
        setComments(data);
      },
    });
  };

  const handleSaveStatusChange = (data) => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    genericPut({
      url: `/eventsparticipant/${data.EventParticipants[0].id}`,
      formData: {
        eventParticipantStatus:
          data?.EventParticipants[0].eventParticipantStatus,
      },
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          fetchCongEvents();
        }
      },
    });
  };

  const handleStatusChange = async (index, option) => {
    const newData = [...tableOption.data];
    const oldValue = newData[index].EventParticipants[0].eventParticipantStatus;
    const newValue = option.value;

    const oldStatus = attendanceStatuses.find(
      (status) =>
        status.value ===
        newData[index].EventParticipants[0].eventParticipantStatus
    ).label;

    if (oldValue.toLowerCase() !== newValue.toLowerCase()) {
      try {
        if (newValue === "Declined") {
          newData[index] = {
            ...newData[index], // Keep all other properties
            EventParticipants: [
              {
                ...newData[index].EventParticipants[0],
                eventParticipantStatus: newValue, // Update the status
              },
            ],
          };
          setEventName(newData[index].eventName);
          setEventId(newData[index].id);
          setCongEvent(newData[index].EventParticipants[0]);
          setIsDeclineModalShown(true);
        } else {
          const result = await Swal.fire({
            title: "Warning",
            // eslint-disable-next-line max-len
            text: `Are you sure you want to change the status from ${oldStatus} to ${option.label}?`,
            icon: "warning",
            confirmButtonText: "OK",
            showCancelButton: true,
          });

          if (result.isConfirmed) {
            newData[index] = {
              ...newData[index], // Keep all other properties
              EventParticipants: [
                {
                  ...newData[index].EventParticipants[0],
                  eventParticipantStatus: newValue, // Update the status
                },
              ],
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });

            handleSaveStatusChange(newData[index], index);
          } else {
            newData[index] = {
              ...newData[index],
              eventParticipantStatus: oldValue,
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const getIndex = (property) => {
    return logs?.findIndex((log) => log.data[property]);
  };

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 10,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  const getStatusOptionsPosition = (index) => {
    if (index >= rowCount - 2) {
      return "top";
    }

    return "bottom";
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "Event Name",
      selector: (eventName) => eventName,
      grow: 4,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventName }) => <p className="mb-0">{eventName}</p>,
    },
    {
      name: "Date Period",
      selector: (Event) => Event,
      grow: 2,
      sortable: true,
      width: "210px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventStartDate, eventEndDate }) => (
        <p className="mb-0">
          {getFormattedDateString(eventStartDate, "MMM dd, yyyy")}
          {" - "}
          {getFormattedDateString(eventEndDate, "MMM dd, yyyy")}
        </p>
      ),
    },
    {
      name: "Time",
      selector: (Event) => Event,
      grow: 1,
      sortable: true,
      width: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventStartTime, eventEndTime }) => (
        <p className="mb-0 wrap-text">
          {formatTime(eventStartTime)}
          {" - "}
          {formatTime(eventEndTime)}
        </p>
      ),
    },
    {
      name: "Venue",
      selector: (eventVenue) => eventVenue,
      grow: 3,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventVenue }) => <p className="mb-0">{eventVenue}</p>,
    },
    {
      name: "Mode",
      selector: (mode) => mode,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ mode }) => <p className="mb-0">{mode}</p>,
    },
    {
      name: "Status",
      selector: (EventParticipants) =>
        EventParticipants[0].eventParticipantStatus,
      identifier: "eventParticipantStatus",
      width: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ EventParticipants }, index) => (
        <CustomSelect
          isSearchable={false}
          onChange={(option) => {
            handleStatusChange(index, option);
          }}
          value={attendanceStatuses.find(
            (stat) =>
              stat?.value === EventParticipants[0].eventParticipantStatus
          )}
          options={getStatusOptions(
            EventParticipants[0].eventParticipantStatus
          )}
          styles={attendanceStatusSelectStyles}
          classNamePrefix="select"
          menuPlacement={getStatusOptionsPosition(index)}
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(option) => {
            return option?.displayLabel;
          }}
          menuPortalTarget={document.body}
        />
      ),
    },
    {
      name: "Remarks",
      selector: ({ remarks }) => remarks,
      identifier: "remarks",
      grow: 2,
      width: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ EventParticipants, id: rowId }) => (
        <CommentsContainer>
          {EventParticipants[0].hasComments ? (
            <ViewCommentBtn
              size="sm"
              onClick={() => {
                fetchComments(rowId);
                setCommentId(rowId);
                toggleModal();
              }}
            >
              {"View Comment"}
            </ViewCommentBtn>
          ) : (
            <AddCommentBtn
              size="sm"
              onClick={() => {
                setCommentId(rowId);
                toggleModal();
              }}
            >
              {"Add Comment"}
            </AddCommentBtn>
          )}
        </CommentsContainer>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row?.id,
      sortable: false,
      width: "120px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => (
        <>
          <Eye
            size={16}
            id={`view-row--${index}`}
            onClick={() => {
              navigate("/events/view", {
                state: {
                  row,
                  action: "view",
                  repDetails: congDetails,
                  regionId: id,
                  congId: congID,
                },
              });
            }}
            className="mr-05"
            color="#4252C9"
          />
          <UncontrolledTooltip
            placement="auto"
            target={`view-row--${index}`}
          >
            {"View"}
          </UncontrolledTooltip>
        </>
      ),
    },
  ];

  const hasCongCosAccess = async (congId) => {
    await genericGet({
      url: `auth/has-access?congressman=${congId}`,
      callback: (data) => {
        setHasAccess(data.data);
      },
    });
  };

  useEffect(() => {
    if (hasAccess) {
      fetchCongEvents();
    }
  }, [
    hasAccess,
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
  ]);

  useEffect(() => {
    hasCongCosAccess(congID);
    setCongDetails(repDetails);
    setLogs(congLogs);
  }, [congID]);

  return (
    <div className="m-0 p-0 cong-container">
      <Row className="cong-header d-flex justify-content-between m-0 px-5 align-items-end">
        <Col
          xs="6"
          className="header-text"
        >
          <h2 className="text-danger">{"Events"}</h2>
        </Col>
        <Col
          xs="6"
          className="d-flex justify-content-end align-items-center custom-breadcrumb--comtainer"
        >
          {!isRoleCongressman ? (
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "House Members",
                  redirect: "/representatives",
                },
                {
                  name: congDetails?.District?.Region?.acronym,
                  redirect: `/representatives/${id}`,
                },
                {
                  name: congDetails?.fullName,
                  redirect: `representatives/${id}/cong/${congID}`,
                },
                {
                  name: "Events",
                  isActive: true,
                },
              ]}
            />
          ) : (
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Events",
                  isActive: true,
                },
              ]}
            />
          )}
        </Col>
      </Row>
      <Row className="m-0 px-5 mt-4 mb-4 d-flex align-items-center cong-details">
        <Col
          xs="auto"
          className="d-flex flex-col flex-direction-col align-items-center"
        >
          <ImgContainer
            src={congDetails?.photo}
            height="230px"
            className="cong-img"
          />
        </Col>
        <Col className="d-flex flex-column">
          <Row>
            <Col
              xs="6"
              className="details-section"
            >
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Committee"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  <>
                    {getIndex("committeeChairmanship") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="committeeChairmanship"
                        />

                        <Tooltip
                          id="committeeChairmanship"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("committeeChairmanship")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("committeeChairmanship")]
                                ?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span className="ml-25">
                      {congDetails?.committeeChairmanship}
                    </span>
                  </>
                </Col>
              </Row>
              {congDetails?.District?.Region?.name !== "Partylist" ? (
                // eslint-disable-next-line react/jsx-indent
                <Row className="mb-2">
                  <Col xs="4">
                    <span className="text-muted">{"Region"}</span>
                  </Col>
                  <Col
                    xs="8"
                    className="d-flex align-items-center"
                  >
                    <>
                      {getIndex("RegionId") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="RegionId"
                          />

                          <Tooltip
                            id="RegionId"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("RegionId")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("RegionId")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">
                        {congDetails?.District?.Region !== null
                          ? congDetails?.District?.Region?.name
                          : ""}
                      </span>
                    </>
                  </Col>
                </Row>
              ) : null}
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">
                    {congDetails?.District?.Region?.name !== "Partylist"
                      ? "District"
                      : "Partylist"}
                  </span>
                </Col>
                <Col
                  xs="8"
                  className="align-items-center"
                >
                  <>
                    {getIndex("DistrictId") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="DistrictId"
                        />

                        <Tooltip
                          id="DistrictId"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("DistrictId")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("DistrictId")]?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span className="ml-25">{congDetails?.District?.name}</span>
                  </>
                </Col>
              </Row>
              {congDetails?.District?.Region?.name !== "Partylist" ? (
                <Row className="mb-2">
                  <Col xs="4">
                    <span className="text-muted">{"Party"}</span>
                  </Col>
                  <Col
                    xs="8"
                    className="d-flex align-items-center"
                  >
                    <>
                      {getIndex("PartyId") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="PartyId"
                          />

                          <Tooltip
                            id="PartyId"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("PartyId")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("PartyId")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">
                        {congDetails?.Party?.acronym}
                      </span>
                    </>
                  </Col>
                </Row>
              ) : null}
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Term of Office"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  <>
                    {getIndex("termOfOffice") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="termOfOffice"
                        />

                        <Tooltip
                          id="termOfOffice"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("termOfOffice")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("termOfOffice")]?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}

                    <span className="ml-25">{congDetails?.termOfOffice}</span>
                  </>
                </Col>
              </Row>
            </Col>
            <Col
              xs="6"
              className="details-section"
            >
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Birthdate"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  <>
                    {getIndex("birthdate") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="birthdate"
                        />

                        <Tooltip
                          id="birthdate"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("birthdate")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("birthdate")]?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span className="ml-25">
                      {congDetails?.birthdate
                        ? moment(congDetails?.birthdate).format(
                            "MMM DD, YYYY"
                          ) || null
                        : null}
                    </span>
                  </>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Contact Number"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  <>
                    {getIndex("contact") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="contact"
                        />

                        <Tooltip
                          id="contact"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("contact")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("contact")]?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span className="ml-25">{congDetails?.contact}</span>
                  </>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Chief of Staff"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  <>
                    {getIndex("cosName") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="cosName"
                        />

                        <Tooltip
                          id="cosName"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("cosName")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("cosName")]?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span className="ml-25">{congDetails?.cosName}</span>
                  </>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Contact Number"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  <>
                    {getIndex("cosContact") !== -1 ? (
                      <>
                        <Info
                          size={18}
                          data-tooltip-id="cosContact"
                        />

                        <Tooltip
                          id="cosContact"
                          style={{
                            backgroundColor: "black",
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {"Updated By:"}
                          </div>

                          <span className="d-flex justify-content-center">
                            {logs[getIndex("cosContact")]?.createdBy}
                          </span>

                          <span className="d-flex justify-content-center">
                            {`at ${getFormattedDateString(
                              logs[getIndex("cosContact")]?.createdAt,
                              "MMM dd, yyyy"
                            )}`}
                          </span>
                        </Tooltip>
                      </>
                    ) : null}
                    <span className="ml-25">{congDetails?.cosContact}</span>
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="table">
        <Row
          className="d-flex mx-5 table-section"
          style={{ flexWrap: "nowrap" }}
        >
          <Col xs="auto" />
          <Col
            xs="12"
            lg="10"
            sm="9"
            xl="11"
          >
            <TableSection>
              <CustomDataTable
                columns={columns}
                data={tableOption.data}
                noHeader
                paginationPerPage={tableOption.currentRowsPerPage}
                paginationServer
                paginationTotalRows={tableOption.totalRows}
                progressPending={tableOption.isLoading}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            </TableSection>
          </Col>
        </Row>
      </div>

      <CommentsModal
        isOpen={isModalShown}
        handleClose={toggleModal}
        comments={comments}
        fetchComments={fetchComments}
        congID={commentId}
        view="events"
        handleFetchTable={fetchCongEvents}
      />

      <DeclineModal
        isOpen={isDeclineModalShown}
        event={congEvent}
        handleClose={handleCloseModal}
        fetchComments={fetchComments}
        fetchDataTable={fetchCongEvents}
        eventName={eventTitle}
        eventId={eveId}
        view="rep"
      />
    </div>
  );
};

export default Main;
