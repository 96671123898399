import React, { useEffect, useState } from "react";
import MessageIcon from "assets/img/comment_fill.png";
import ImgContainer from "components/common/img";
import { Toast, getFormattedDateString, hasPermission } from "utility/helpers";
import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Send } from "react-feather";
import PropTypes from "prop-types";
import { markComment } from "services/services.projects";
import _ from "lodash";
import { genericPost } from "services/services.general";
import SupportingDocumentsModal from "../supportingDocsModal";

import "./style.scss";

const CommentsModal = ({
  isOpen,
  handleClose,
  comments,
  congID,
  congressmanId,
  fetchComments,
  programID,
  selectedAgency,
  handleFetchTable,
  view,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isSupportingModalOpen, setIsSupportingModalOpen] = useState(false);
  const [commentsArray, setCommentsArray] = useState(comments);
  const [isReply, setIsReply] = useState(false);
  const [parentCommentId, setParentCommentId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleReplyMessage = () => {
    if (inputValue) {
      setIsLoading(true);

      genericPost({
        url: `/comment/${parentCommentId}/reply`,
        formData: {
          text: inputValue,
        },
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            fetchComments(congID);
            setInputValue("");
            setIsReply(false);
            setParentCommentId("");
            setIsLoading(false);
            handleFetchTable();
          }
        },
      });
    } else {
      Toast({
        type: 4,
        content: "Reply must not be empty",
      });
    }
  };

  const handleSetSupportingDocumentsModalToggle = () => {
    setIsSupportingModalOpen(!isSupportingModalOpen);
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = _.debounce(() => {
    if (isReply) return handleReplyMessage();

    if (inputValue) {
      setIsLoading(true);

      genericPost({
        url: "/comment",
        formData: {
          text: inputValue,
          recipientId: !view ? congID : null,
          newsId: view === "news" ? congID : null,
          eventId: view === "events" ? congID : null,
        },
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            fetchComments(congID);
            setInputValue("");
            handleFetchTable();
            setIsLoading(false);
          }
        },
      });
    } else {
      Toast({
        type: 4,
        content: "Comment must not be empty",
      });
    }
  }, 2000);

  const handleMarkAsRead = (comment) => {
    if (!comment.isRead) {
      markComment({
        param: comment.id,
        formData: {
          isRead: true,
        },
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            fetchComments(congID);
            handleFetchTable();
          }
        },
      });
    }
  };

  useEffect(() => {
    const coms = [];

    if (comments?.length !== 0) {
      // eslint-disable-next-line array-callback-return
      comments.map((com) => {
        const comment = {
          createdAt: com?.createdAt,
          id: com?.id,
          createdBy: com?.user?.createdBy,
          position:
            com?.user?.position !== "No Available Position yet"
              ? com?.user?.position
              : "",
          text: com?.text,
          isRead: com?.isRead,
        };

        if (!coms.find((c) => c?.id === com?.id)) {
          coms.push(comment);
        }

        if (com?.replies?.length !== 0) {
          // eslint-disable-next-line array-callback-return
          com.replies.map((reply) => {
            const rep = {
              createdAt: reply?.createdAt,
              id: reply?.id,
              createdBy: reply?.user?.createdBy,
              position:
                reply?.user?.position !== "No Available Position yet"
                  ? reply?.user?.position
                  : "",
              text: reply?.text,
              repliedTo: reply?.user?.repliedTo,
              isRead: com?.isRead,
            };

            coms.push(rep);
          });
        }
      });
    }

    setCommentsArray(coms);
  }, [comments]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        handleClose();
        setCommentsArray([]);
      }}
      className="comments-modal"
    >
      <ModalHeader
        toggle={() => {
          handleClose();
          setCommentsArray([]);
        }}
      >
        <div className="d-flex flex-row align-items-center">
          <ImgContainer src={MessageIcon} />
          <p className="comments-text">{"Comments"}</p>
        </div>
      </ModalHeader>
      <Row className="d-flex w-100 align-items-center m-0">
        <Col
          xs="6"
          className="header-left"
        />
        <Col
          xs="6"
          className="header-right"
        />
      </Row>

      <ModalBody className="d-grid">
        <div className="mx-2 mt-4 d-grid">
          {commentsArray?.length === 0 ? (
            <div className="d-flex justify-content-center flex-column">
              <p className="no-comments-text">{"No Comments"}</p>

              <p className="sub-text text-center">
                {"Be the first to comment in this program"}
              </p>
            </div>
          ) : (
            <div className="comment-container">
              {commentsArray?.map((com) => {
                return (
                  <div
                    key={com?.id}
                    onClick={() => handleMarkAsRead(com)}
                    aria-hidden="true"
                    id={com?.id}
                  >
                    <div>
                      <p className={`user ${com?.isRead ? "unread" : ""}`}>
                        {com?.createdBy}
                      </p>
                      <p className={com?.repliedTo ? "mb-0" : ""}>
                        {com?.position !== "" ? (
                          <small className="">{com?.position}</small>
                        ) : null}
                        <small>
                          {getFormattedDateString(
                            com.createdAt,
                            "EEEE, MMM dd, yyyy"
                          )}
                        </small>
                      </p>
                      {com?.repliedTo && (
                        <p>
                          <small className="">{`Replied to ${com?.repliedTo}`}</small>
                        </p>
                      )}
                    </div>
                    <div>
                      <p className="user-comment">{com?.text}</p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <a
                        className="reply"
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsReply(true);
                          setParentCommentId(com?.id);
                        }}
                      >
                        {"Reply"}
                      </a>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <div>
          <Input
            className={`${isReply ? "is-focused" : ""}`}
            placeholder="Add Comment"
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
          />

          {!view && (
            <button
              type="button"
              onClick={handleSetSupportingDocumentsModalToggle}
              className="input-comment-attachment-button btn btn-light text-white"
            >
              {"See Supporting Documents"}
            </button>
          )}
        </div>

        <div className="send-btn-section">
          {hasPermission("comment-create") && (
            <a
              href="!#"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="dark"
                  size="sm"
                />
              ) : (
                <Send
                  color="#4252c9"
                  size={20}
                />
              )}
            </a>
          )}
        </div>
      </ModalFooter>

      <SupportingDocumentsModal
        congID={congID}
        congressmanId={congressmanId}
        isOpen={isSupportingModalOpen}
        programID={programID}
        selectedAgency={selectedAgency}
        setModal={handleSetSupportingDocumentsModalToggle}
      />
    </Modal>
  );
};

CommentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  comments: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  congID: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  congressmanId: PropTypes.string,
  fetchComments: PropTypes.func.isRequired,
  programID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedAgency: PropTypes.oneOfType([PropTypes.object]),
  handleFetchTable: PropTypes.func,
};

export default CommentsModal;
