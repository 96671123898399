import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import { Col, Row, Spinner } from "reactstrap";
import { Check } from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import BreadCrumbComponent from "components/customs/breadCrumb";
import FormGenertor from "components/customs/form-generator/input";
import CustomCheckbox from "components/customs/checkbox";
import CustomDropzone from "views/projects/add/Dropzone";
import CustomSelect from "components/customs/dropdown";
import { getUserDetails } from "config";

import { genericGet, genericPost, genericPut } from "services/services.general";
import {
  fetchAllRegions,
  fetchCongressmanDetails,
} from "services/services.congressman";

import {
  accountCustomStyle,
  DropdownIndicator2,
  canEditRoles,
} from "utility/constants";
import { formatTimeToServer, Toast } from "utility/helpers";
import RecipientTable from "./Table";

import {
  BreadCrumbSection,
  HeaderTextSection,
  MainContainer,
  Container,
  FormSection,
  RowSection,
  StyledRed,
  FormColRight,
  CharacterCountSection,
  SendWithOptions,
  BtnSend,
  BtnSection,
  BtnCancel,
} from "../styled";

import "./styles.scss";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const allRegion = {
  value: "all",
  label: "All",
};

const customRegion = {
  value: 0,
  label: "Custom",
};

const isNotAllOrCustom = (reg) => reg.value !== "all" && reg.value !== 0;

const AddEventForm = () => {
  const location = useLocation();
  const { row, action } = location.state || null;

  const role = getUserDetails()?.type;

  const [form, setForm] = React.useState({
    eventName: "",
    eventStartDate: "",
    eventEndDate: "",
    eventStartTime: "",
    eventEndTime: "",
    eventVenue: "",
    eventDescription: "",
    excludedCongs: "",
    sendWithSMS: true,
    sendWithEmail: false,
    includeCOS: false,
    rsvpStartDate: "",
    rsvpEndDate: "",
    message: "",
    attachedFileURL: "",
    officials: "",
  });

  const [recipients, setRecipients] = React.useState({
    congInvitees: [],
    excludedCongs: [],
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [regionOptions, setRegionOptions] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [selectedRecipients, setSelectedRecipients] = React.useState([]);
  const [isCustomSelected, setIsCustomSelected] = React.useState(false);
  const [startEventDate, setEventStartDate] = React.useState(null);
  const [endEventDate, setEventEndDate] = React.useState(null);
  const [selectedStartTime, setSelectedStartTime] = React.useState(null);
  const [selectedEndTime, setSelectedEndTime] = React.useState(null);
  const [startRSVPDate, setRSVPStartDate] = React.useState(null);
  const [endRSVPDate, setRSVPEndDate] = React.useState(null);

  const navigate = useNavigate();

  const regions = regionOptions?.filter(isNotAllOrCustom) || [];

  const resetForm = () => {
    setRecipients({
      include: [],
      exclude: [],
    });

    setForm({
      eventName: "",
      eventStartDate: "",
      eventEndDate: "",
      eventStartTime: "",
      eventEndTime: "",
      eventVenue: "",
      eventDescription: "",
      excludedCongs: "",
      sendWithSMS: false,
      sendWithEmail: false,
      includeCOS: false,
      rsvpStartDate: "",
      rsvpEndDate: "",
      message: "",
      attachedFileURL: "",
      officials: "",
    });

    setUploadedFiles([]);
    setSelectedRegion(null);
    setSelectedRecipients([]);
  };

  const sendData = (formData) => {
    genericPost({
      url: "/events",
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (isSuccess) {
          navigate("/events");

          Toast({
            type: 1,
            content: "Successfully created event.",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured.",
          });
        }
      },
    });
  };

  const sendEmail = (formData) => {
    genericPost({
      url: "/events/send-email",
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (!isSuccess) {
          Toast({
            type: 4,
            content: "An error occured when sending emails.",
          });
        }
      },
    });
  };

  const sendFinalDraft = (formData) => {
    genericPut({
      url: `/events/${row?.id}`,
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (isSuccess) {
          resetForm();
          navigate("/events");

          Toast({
            type: 1,
            content: "Successfully edited event.",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured.",
          });
        }
      },
    });
  };

  const validate = () => {
    if (!form.eventName) {
      return "Event name cannot be empty";
    }

    if (!startEventDate || !endEventDate) {
      return "Event dates cannot be empty";
    }

    if (!selectedStartTime || !selectedEndTime) {
      return "Event times cannot be empty";
    }

    if (!form.eventVenue) {
      return "Event venue cannot be empty";
    }

    if (!selectedRegion) {
      return "Region cannot be empty";
    }

    if (recipients.congInvitees.length === 0) {
      return "Recipients cannot be empty";
    }

    if (!startRSVPDate || !endRSVPDate) {
      return "RSVP dates cannot be empty";
    }

    if (!form.message) {
      return "Message cannot be empty";
    }

    return true;
  };

  const getRegionId = () => {
    if (selectedRegion.some((region) => region.value === 0)) return null;

    if (selectedRegion.some((region) => region.value === "all")) {
      return regions.map((op) => op.value).join(",");
    }

    return selectedRegion?.map((region) => region.value).join(",");
  };

  // eslint-disable-next-line consistent-return
  const handleSendOnClicked = () => {
    const isValid = validate();

    if (typeof isValid !== "boolean") {
      return Toast({
        type: 4,
        content: isValid,
      });
    }

    setIsLoading(true);

    const commonFields = {
      eventName: form.eventName,
      message: form.message,
      eventStartDate: startEventDate,
      eventEndDate: endEventDate,
      eventVenue: form.eventVenue,
      rsvpStartDate: startRSVPDate,
      rsvpEndDate: endRSVPDate,
    };

    const formData = {
      ...commonFields,
      eventStartTime: selectedStartTime,
      eventEndTime: selectedEndTime,
      eventDescription: form.eventDescription,
      congInvitees: recipients.congInvitees.join(";"),
      excludedCongs: recipients.excludedCongs.join(";"),
      sendWithSMS: form.sendWithSMS,
      sendWithEmail: form.sendWithEmail,
      includeCOS: form.includeCOS,
      attachedFileURL: uploadedFiles?.map((file) => file.location),
      regionId: getRegionId(),
    };

    const emailForm = {
      ...commonFields,
      includeCOS: form.includeCOS ? 1 : 0,
      recipients: recipients.congInvitees.join(";"),
      eventStartTime: formatTimeToServer(selectedStartTime),
      eventEndTime: formatTimeToServer(selectedEndTime),
    };

    if (row) {
      formData.id = row.id;
      sendFinalDraft(formData);
    } else {
      sendData(formData);
      if (form.sendWithEmail) sendEmail(emailForm);
    }
  };

  const handleUploadedFiles = (link) => {
    setUploadedFiles((prevUploadedFiles) => {
      const fileIndex = prevUploadedFiles.findIndex(
        (file) => file.name === link.name
      );

      if (fileIndex !== -1) {
        const updatedFiles = [...prevUploadedFiles];
        updatedFiles[fileIndex] = link;

        return updatedFiles;
      }

      if (prevUploadedFiles.length < 5) {
        return [...prevUploadedFiles, link];
      }

      Toast({
        type: 4,
        content: "Exceeded file upload limit of 5",
      });

      return prevUploadedFiles;
    });
  };

  const handleDeleteFile = (file) => {
    const newUploadedFiles = uploadedFiles.filter(
      (files) => files.name !== file
    );
    setUploadedFiles(newUploadedFiles);
  };

  const fetchRegions = () => {
    fetchAllRegions({
      params: {
        includeCentral: false,
      },
      callback: (data) => {
        if (data.length) {
          const options = data
            .filter(({ omegaId, id }) => omegaId !== null || id === 18)
            .map(({ acronym, id, omegaId }) => ({
              omegaId,
              label: acronym,
              value: id,
            }));

          options.unshift(
            {
              value: "all",
              label: "All",
            },
            {
              value: 0,
              label: "Custom",
            }
          );

          setRegionOptions(options);
        }
      },
    });
  };

  const getFilteredRegionOptions = () => {
    const all = selectedRegion?.length
      ? selectedRegion?.some((op) => op?.value === "all")
      : false;

    const custom = selectedRegion?.length
      ? selectedRegion?.some((op) => op?.value === 0)
      : false;

    if (custom) return [allRegion];

    return all ? [] : regionOptions;
  };

  const handleRegionOnChange = (option) => {
    if (option?.some((op) => op.value === 0)) {
      setSelectedRegion([customRegion]);
      setIsCustomSelected(true);

      return;
    }

    if (option.length > 1 && option?.some((op) => op.value === "all")) {
      setSelectedRegion([allRegion]);

      return;
    }

    if (option.length !== 0 && option.length === regions.length) {
      setSelectedRegion([allRegion]);

      return;
    }

    setIsCustomSelected(false);
    setSelectedRegion(option);
  };

  const setFromData = () => {
    setForm({
      eventName: row.eventName,
      eventDescription: row.eventDescription,
      eventVenue: row.eventVenue,
      message: row.message,
      includeCOS: row.includeCOS,
      sendWithSMS: row.sendWithSMS,
      sendWithEmail: row.sendWithEmail,
      eventStartDate: row.eventStartDate,
      eventEndDate: row.eventEndDate,
      eventStartTime: row.eventStartTime,
      eventEndTime: row.eventEndTime,
      rsvpStartDate: row.rsvpStartDate,
      rsvpEndDate: row.rsvpEndDate,
      attachedFileURL: row.attachedFileURL,
    });

    setEventStartDate(row.eventStartDate ? new Date(row.eventStartDate) : null);
    setEventEndDate(row.eventEndDate ? new Date(row.eventEndDate) : null);

    setSelectedStartTime(
      row.eventStartTime ? new Date(`1970-01-01T${row.eventStartTime}`) : null
    );

    setSelectedEndTime(
      row.eventEndTime ? new Date(`1970-01-01T${row.eventEndTime}`) : null
    );
    setRSVPStartDate(row.rsvpStartDate ? new Date(row.rsvpStartDate) : null);
    setRSVPEndDate(row.rsvpEndDate ? new Date(row.rsvpEndDate) : null);

    const files = [];

    if (row?.attachedFileURL?.lenght !== 0) {
      row?.attachedFileURL?.forEach((fileURL) => {
        const fileArray = fileURL.split("/");

        const file = {
          name: decodeURIComponent(fileArray[fileArray.length - 1]),
          location: fileURL,
          percent: 100,
        };

        files.push(file);
      });
    }

    setUploadedFiles(files);
  };

  const fetchAllRegionalCongs = () => {
    const regs = selectedRegion?.map((region) => region.value).join(",");

    genericGet({
      url: "congressman/lookup/regions",
      params: {
        region: regs,
        pagination: false,
      },
      callback: (data) => {
        if (data?.rows?.length !== 0) {
          const newData = data?.rows?.map(
            ({ id, fullName, District, contact }) => ({
              name: fullName,
              id,
              area: District?.Region?.acronym || "",
              contact,
            })
          );

          setSelectedRecipients(newData);
          const included = row?.recipients?.split(";");

          const includedIds = !included
            ? data?.rows?.map(({ id }) => id)
            : data?.rows
                ?.filter(({ id }) => included.includes(id.toString()))
                .map(({ id }) => id);

          setRecipients({
            ...recipients,
            congInvitees: includedIds,
          });
        } else {
          setSelectedRecipients([]);

          setRecipients({
            ...recipients,
            congInvitees: [],
          });
        }
      },
    });
  };

  const setSelectedCustomRecipients = async () => {
    try {
      setIsLoading(true);
      let included = row?.recipients?.split(";");
      included = included.map((id) => Number(id));
      const selecRecipients = [];

      const promises = included.map((recipient) => {
        return new Promise((resolve) => {
          fetchCongressmanDetails({
            url: `/congressman/${recipient}`,
            callback: (data) => {
              const newData = {
                name: data.fullName,
                id: data.id,
                designation: "",
                contact: data.contact,
                agency: "",
                area: data.District.Region.acronym,
              };
              selecRecipients.push(newData);
              resolve();
            },
          });
        });
      });

      await Promise.all(promises);

      setSelectedRecipients(selecRecipients);

      setRecipients({
        ...recipients,
        congInvitees: included,
      });

      setIsLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (canEditRoles.includes(role)) {
      if (row && regionOptions.length) {
        if (row.regionIds) {
          const regs = row.regionIds?.split(",");

          const region = regionOptions.filter((reg) =>
            regs.includes(reg.value.toString())
          );

          if (regions.length === regs.length) setSelectedRegion([allRegion]);
          else setSelectedRegion(region);
        } else {
          setSelectedRegion([customRegion]);
          setSelectedCustomRecipients();
        }
      }
    }
  }, [row, regionOptions]);

  useEffect(() => {
    if (row) setFromData();
  }, [row, action]);

  useEffect(() => {
    if (selectedRegion) {
      if (
        selectedRegion.length &&
        selectedRegion.some((op) => op.value !== 0)
      ) {
        fetchAllRegionalCongs();
      } else {
        setSelectedRecipients([]);

        setRecipients({
          ...recipients,
          congInvitees: [],
        });
      }
    }
  }, [selectedRegion]);

  const getHeaderText = () => {
    if (action === "edit") return "Edit an Event";
    if (action === "view") return "Event Details";

    return "Add an Event";
  };

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          {canEditRoles.includes(role) ? (
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Events",
                  redirect: "/events",
                },
                {
                  name: row ? "Edit Event" : "Create Event",
                  isActive: true,
                },
              ]}
            />
          ) : null}
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">{getHeaderText()}</h2>
        </HeaderTextSection>

        <FormSection className="add-event-form">
          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Name"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the name of the event"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Name"
                className="form-control"
                value={form.eventName}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const { value } = event.target;

                  setForm({
                    ...form,
                    eventName: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection className="date-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Date"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the date of the event"}</p>
            </Col>
            <FormColRight>
              <Row>
                <Col md="6">
                  <DatePicker
                    selected={startEventDate}
                    placeholderText="Start Date"
                    className="form-control input-date"
                    dateFormat="MMM-dd-yyyy"
                    minDate={new Date()}
                    onChange={(date) => {
                      setEventStartDate(date);
                    }}
                    readOnly={action === "view"}
                  />
                </Col>
                <Col md="6">
                  <DatePicker
                    selected={endEventDate}
                    placeholderText="End Date"
                    className="form-control input-date"
                    dateFormat="MMM-dd-yyyy"
                    minDate={new Date()}
                    onChange={(date) => {
                      setEventEndDate(date);
                    }}
                    readOnly={action === "view"}
                  />
                </Col>
              </Row>
            </FormColRight>
          </RowSection>

          <RowSection className="date-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Time"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the time of the event"}</p>
            </Col>
            <FormColRight>
              <Row>
                <Col md="6">
                  <DatePicker
                    selected={selectedStartTime}
                    className="form-control input-date"
                    onChange={(time) => setSelectedStartTime(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    placeholderText="Start Time"
                    timeFormat="hh:mm aa"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    readOnly={action === "view"}
                  />
                </Col>
                <Col md="6">
                  <DatePicker
                    selected={selectedEndTime}
                    className="form-control input-date"
                    onChange={(time) => setSelectedEndTime(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    placeholderText="End Time"
                    timeFormat="hh:mm aa"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    readOnly={action === "view"}
                  />
                </Col>
              </Row>
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Venue"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the location of the event"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Venue"
                className="form-control"
                value={form.eventVenue}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const { value } = event.target;

                  setForm({
                    ...form,
                    eventVenue: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Description"}</h5>
              <p>{"Provide the description of the event"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="textarea"
                placeholder="Description"
                className="w-100 input-textarea form-control"
                rows="5"
                value={form.eventDescription}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const { value } = event.target;

                  setForm({
                    ...form,
                    eventDescription: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          {canEditRoles.includes(role) && (
            <RowSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Invitees By Region"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the region of the invitees"}</p>
              </Col>
              <FormColRight>
                <CustomSelect
                  classNamePrefix="select"
                  placeholder="Region"
                  options={getFilteredRegionOptions()}
                  onChange={handleRegionOnChange}
                  styles={accountCustomStyle}
                  value={selectedRegion}
                  components={{ DropdownIndicator: DropdownIndicator2 }}
                  isDisabled={action !== "add"}
                  isMulti
                />
              </FormColRight>
            </RowSection>
          )}

          {canEditRoles.includes(role) && (
            <RecipientTable
              data={selectedRecipients}
              setRecipients={setRecipients}
              recipients={recipients}
              action={action}
              isCustomSelected={isCustomSelected}
            />
          )}

          {canEditRoles.includes(role) && (
            <RowSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Include the COS"}</h5>
              </Col>
              <FormColRight>
                <CustomCheckbox
                  checked={form.includeCOS}
                  label="COS"
                  disabled={action !== "add"}
                  icon={
                    <Check
                      className="vx-icon"
                      size={16}
                    />
                  }
                  onChange={(evt) => {
                    const { checked } = evt.target;

                    setForm({
                      ...form,
                      includeCOS: checked,
                    });
                  }}
                />
              </FormColRight>
            </RowSection>
          )}

          {canEditRoles.includes(role) && (
            <RowSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Send with?"}</h5>
              </Col>
              <FormColRight>
                <SendWithOptions>
                  <CustomCheckbox
                    className="mr-2"
                    checked={form.sendWithSMS}
                    label="SMS"
                    disabled={action !== "add"}
                    icon={
                      <Check
                        className="vx-icon"
                        size={16}
                      />
                    }
                    onChange={(evt) => {
                      const { checked } = evt.target;

                      setForm({
                        ...form,
                        sendWithSMS: checked,
                      });
                    }}
                  />

                  <CustomCheckbox
                    checked={form.sendWithEmail}
                    label="Email"
                    disabled={action !== "add"}
                    icon={
                      <Check
                        className="vx-icon"
                        size={16}
                      />
                    }
                    onChange={(evt) => {
                      const { checked } = evt.target;

                      setForm({
                        ...form,
                        sendWithEmail: checked,
                      });
                    }}
                  />
                </SendWithOptions>
              </FormColRight>
            </RowSection>
          )}

          <RowSection className="date-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"RSVP Date"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the date of response"}</p>
            </Col>
            <FormColRight>
              <Row>
                <Col md="6">
                  <DatePicker
                    selected={startRSVPDate}
                    placeholderText="Start Date"
                    className="form-control input-date"
                    dateFormat="MMM-dd-yyyy"
                    minDate={new Date()}
                    onChange={(date) => {
                      setRSVPStartDate(date);
                    }}
                    readOnly={action === "view"}
                  />
                </Col>
                <Col md="6">
                  <DatePicker
                    selected={endRSVPDate}
                    placeholderText="End Date"
                    className="form-control input-date"
                    dateFormat="MMM-dd-yyyy"
                    minDate={new Date()}
                    onChange={(date) => {
                      setRSVPEndDate(date);
                    }}
                    readOnly={action === "view"}
                  />
                </Col>
              </Row>
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Message"}
                {RequiredIndicator}
              </h5>
              <p>{"Write your message here"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="textarea"
                placeholder="Compose a message"
                className="w-100 input-textarea form-control"
                rows="5"
                value={form.message}
                maxLength={160}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const { value } = event.target;

                  if (value.length !== 160) {
                    setForm({
                      ...form,
                      message: value,
                    });
                  }
                }}
              />
              <CharacterCountSection>
                {`Characters remaining: ${160 - form.message.length}`}
              </CharacterCountSection>
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Supporting Documents"}</h5>
              <p>{"Upload the supporting documents"}</p>
            </Col>
            <FormColRight>
              <CustomDropzone
                handleUploadedFiles={handleUploadedFiles}
                handleDeleteFile={handleDeleteFile}
                uploadedFiles={uploadedFiles}
                label="Upload Files"
                isDisabled={action === "view"}
              />
            </FormColRight>
          </RowSection>
        </FormSection>

        <BtnSection>
          <BtnCancel
            disabled={isLoading}
            onClick={() => navigate(-1)}
          >
            {"Back"}
          </BtnCancel>

          <BtnSend
            onClick={() => handleSendOnClicked()}
            disabled={isLoading || action === "view"}
          >
            {isLoading ? (
              <Spinner
                animation="border"
                color="light"
                size="sm"
              />
            ) : (
              "Send"
            )}
          </BtnSend>
        </BtnSection>
      </Container>
    </MainContainer>
  );
};

export default AddEventForm;
