import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { UncontrolledTooltip } from "reactstrap";
import {
  defaultTableOption,
  attendanceStatuses,
  attendanceStatusSelectStyles,
} from "utility/constants";
import CustomSelect from "components/customs/dropdown";
import CustomDataTable from "components/customs/table";
// eslint-disable-next-line no-unused-vars
import { ArrowLeft, RefreshCw } from "react-feather";
import { formatTime, getFormattedDateString, Toast } from "utility/helpers";
import { useLocation, useNavigate } from "react-router";
import { genericGet, genericPost, genericPut } from "services/services.general";
import BreadCrumbComponent from "components/customs/breadCrumb";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import SearchBarComponent from "components/customs/searchbarV2";
import DeclineModal from "components/customs/declineModal";

import {
  MainContainer,
  Container,
  BreadCrumbSection,
  HeaderTextSection,
  StyledCongressmanSection,
  TableSection,
} from "../styled";

const CongressmanEvents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const congressman = location.state?.row;
  const [tableOption, setTableOption] = React.useState(defaultTableOption);

  const [isDeclineModalShown, setIsDeclineModalShown] = useState(false);

  const [congEvent, setCongEvent] = useState(null);
  const [eventTitle, setEventName] = useState(null);
  const [eveId, setEventId] = useState(0);

  const toggleDeclineModal = () => setIsDeclineModalShown(!isDeclineModalShown);

  let changeSearchTimeoutID;

  const getStatusOptions = () => {
    const options = attendanceStatuses;

    return options;
  };

  const fetchCongEvents = () => {
    genericGet({
      url: `congressman/${congressman.congressmanId}/events`,
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          data: data.rows,
          totalRows: data.count,
        });
      },
    });
  };

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 10,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleResendClicked = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to resend #${row.id}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        genericPost({
          url: "/logs/resend-sms",
          formData: {
            notificationIds: row.id.toString(),
          },
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully resend sms.",
              });

              navigate(0);
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const handleCloseModal = () => {
    toggleDeclineModal();
  };

  const handleSaveStatusChange = (data) => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    genericPut({
      url: `/eventsparticipant/${data.EventParticipants[0].id}`,
      formData: {
        eventParticipantStatus:
          data?.EventParticipants[0].eventParticipantStatus,
      },
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          fetchCongEvents();
        }
      },
    });
  };

  const handleStatusChange = async (index, option) => {
    const newData = [...tableOption.data];
    const oldValue = newData[index].EventParticipants[0].eventParticipantStatus;
    const newValue = option.value;

    const oldStatus = attendanceStatuses.find(
      (status) =>
        status.value ===
        newData[index].EventParticipants[0].eventParticipantStatus
    ).label;

    if (oldValue.toLowerCase() !== newValue.toLowerCase()) {
      try {
        if (newValue === "Declined") {
          newData[index] = {
            ...newData[index], // Keep all other properties
            EventParticipants: [
              {
                ...newData[index].EventParticipants[0],
                eventParticipantStatus: newValue, // Update the status
              },
            ],
          };
          setEventName(newData[index].eventName);
          setEventId(newData[index].id);
          setCongEvent(newData[index].EventParticipants[0]);
          setIsDeclineModalShown(true);
        } else {
          const result = await Swal.fire({
            title: "Warning",
            // eslint-disable-next-line max-len
            text: `Are you sure you want to change the status from ${oldStatus} to ${option.label}?`,
            icon: "warning",
            confirmButtonText: "OK",
            showCancelButton: true,
          });

          if (result.isConfirmed) {
            newData[index] = {
              ...newData[index], // Keep all other properties
              EventParticipants: [
                {
                  ...newData[index].EventParticipants[0],
                  eventParticipantStatus: newValue, // Update the status
                },
              ],
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });

            handleSaveStatusChange(newData[index], index);
          } else {
            newData[index] = {
              ...newData[index],
              eventParticipantStatus: oldValue,
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "Event Name",
      selector: (eventName) => eventName,
      grow: 4,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventName }) => <p className="mb-0">{eventName}</p>,
    },
    {
      name: "Date Period",
      selector: (Event) => Event,
      grow: 2,
      sortable: true,
      width: "210px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventStartDate, eventEndDate }) => (
        <p className="mb-0">
          {getFormattedDateString(eventStartDate, "MMM dd, yyyy")}
          {" - "}
          {getFormattedDateString(eventEndDate, "MMM dd, yyyy")}
        </p>
      ),
    },
    {
      name: "Time",
      selector: (Event) => Event,
      grow: 1,
      sortable: true,
      width: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventStartTime, eventEndTime }) => (
        <p className="mb-0 wrap-text">
          {formatTime(eventStartTime)}
          {" - "}
          {formatTime(eventEndTime)}
        </p>
      ),
    },
    {
      name: "Venue",
      selector: (eventVenue) => eventVenue,
      grow: 3,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventVenue }) => <p className="mb-0">{eventVenue}</p>,
    },
    {
      name: "Mode",
      selector: (mode) => mode,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ mode }) => <p className="mb-0">{mode}</p>,
    },
    {
      name: "Status",
      selector: (status) => status,
      width: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ EventParticipants }, index) => (
        <CustomSelect
          isSearchable={false}
          onChange={(option) => {
            handleStatusChange(index, option);
          }}
          value={attendanceStatuses.find(
            (stat) =>
              stat?.value === EventParticipants[0].eventParticipantStatus
          )}
          options={getStatusOptions()}
          styles={attendanceStatusSelectStyles}
          classNamePrefix="select"
          // menuPlacement={getStatusOptionsPosition()}
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(option) => {
            return option?.displayLabel;
          }}
          menuPortalTarget={document.body}
        />
      ),
    },
    {
      name: "Actions",
      selector: (rowData) => rowData?.id,
      sortable: false,
      width: "120px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (rowData) => (
        <>
          <RefreshCw
            size={16}
            id={`resend-row--${rowData.id}`}
            className="mr-05"
            color="#4252C9"
          />
          <UncontrolledTooltip
            placement="auto"
            target={`resend-row--${rowData.id}`}
          >
            {"Refresh"}
          </UncontrolledTooltip>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchCongEvents();
  }, [
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
  ]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/",
              },
              {
                name: "Events",
                redirect: "/events",
              },
              {
                name: "Cong/COS",
                redirect: "/events",
              },
              {
                name: congressman["Congressman.fullName"],
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>
        <HeaderTextSection>
          <h2 className="text-danger">{"Events"}</h2>
        </HeaderTextSection>

        <StyledCongressmanSection>
          <div className="d-flex align-items-center">
            <Link
              to="/events"
              state={{
                view: "CongCos",
              }}
            >
              <ArrowLeft
                size={24}
                className="mr-05"
                color="#000"
              />
            </Link>
            <h3 className="event-cong-name">
              {congressman["Congressman.fullName"]}
            </h3>
          </div>

          <div className="position-relative">
            <SearchBarComponent
              onChangeSearch={onChangeSearch}
              isDisabled={tableOption?.isLoading}
              placeholder="Search"
            />
          </div>
        </StyledCongressmanSection>

        <TableSection>
          <CustomDataTable
            columns={columns}
            data={tableOption.data}
            noHeader
            paginationPerPage={tableOption.currentRowsPerPage}
            paginationServer
            paginationTotalRows={tableOption.totalRows}
            progressPending={tableOption.isLoading}
            height="calc(100vh - 200px)"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </TableSection>
      </Container>
      <DeclineModal
        isOpen={isDeclineModalShown}
        event={congEvent}
        handleClose={handleCloseModal}
        fetchDataTable={fetchCongEvents}
        eventName={eventTitle}
        eventId={eveId}
      />
    </MainContainer>
  );
};

export default CongressmanEvents;
