import React from "react";
import { components } from "react-select";
import dropdownIcon from "assets/img/Ellipse 66.png";
import regionDropdown from "assets/img/regionDropdown.svg";
import dropdown from "assets/img/drpdown.png";

export const congRoles = ["Congressman", "COS"];

export const canEditRoles = ["Editor", "Director"];

export const fundSourceOptions = [
  {
    label: "GAA",
    value: "GAA",
  },
  {
    label: "Unprogrammed",
    value: "Unprogrammed",
  },
];

export const fundSourceDBMOptions = [
  {
    label: "Regular",
    value: "Regular",
  },
  {
    label: "Additional",
    value: "Additional",
  },
];

export const institutionTypes = [
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Government",
    value: "Government",
  },
];

export const statuses = [
  {
    id: 7,
    label: "Requested",
    value: "Requested",
    displayLabel: <div className="requested-status">{"Requested"}</div>,
  },
  {
    id: 1,
    label: "Pending",
    value: "Pending",
    displayLabel: <div className="pending-status">{"Pending"}</div>,
  },
  {
    id: 2,
    label: "Processed",
    value: "Processed",
    displayLabel: <div className="processed-status">{"Processed"}</div>,
  },
  {
    id: 3,
    label: "In-Progress",
    value: "In-Progress",
    displayLabel: <div className="in-progress-status">{"In-Progress"}</div>,
  },
  {
    id: 4,
    label: "Endorsed",
    value: "Approved",
    displayLabel: <div className="approved-status">{"Endorsed"}</div>,
  },
  {
    id: 5,
    label: "Pls See Comment",
    value: "Disapproved",
    displayLabel: <div className="disapproved-status">{"Pls See Comment"}</div>,
  },
  {
    id: 6,
    label: "Downloaded",
    value: "Downloaded",
    displayLabel: <div className="downloaded-status">{"Downloaded"}</div>,
  },
  {
    id: 8,
    label: "For Resubmission",
    value: "For Resubmission",
    displayLabel: (
      <div className="resubmission-status">{"For Resubmission"}</div>
    ),
  },
  {
    id: 9,
    label: "Not Eligible",
    value: "Not Eligible",
    displayLabel: <div className="disapproved-status">{"Not Eligible"}</div>,
  },
];

export const attendanceStatuses = [
  {
    id: 7,
    label: "Invited",
    value: "Invited",
    displayLabel: <div className="invited-status">{"Invited"}</div>,
  },
  {
    id: 1,
    label: "Confirmed",
    value: "Confirmed",
    displayLabel: <div className="confirmed-status">{"Confirmed"}</div>,
  },
  {
    id: 2,
    label: "Declined",
    value: "Declined",
    displayLabel: <div className="declined-status">{"Declined"}</div>,
  },
  {
    id: 3,
    label: "No Response",
    value: "No Response",
    displayLabel: <div className="no-response-status">{"No Response"}</div>,
  },
  {
    id: 4,
    label: "Attended",
    value: "Attended",
    displayLabel: <div className="attended-status">{"Attended"}</div>,
  },
  {
    id: 5,
    label: "Did Not Attend",
    value: "Did Not Attend",
    displayLabel: (
      <div className="did-not-attend-status">{"Did Not Attend"}</div>
    ),
  },
];

export const statusOptions = [
  {
    id: 7,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "Pending",
    value: "Pending",
  },
  {
    id: 2,
    label: "Processed",
    value: "Processed",
  },
  {
    id: 3,
    label: "In-Progress",
    value: "In-Progress",
  },
  {
    id: 4,
    label: "Approved",
    value: "Approved",
  },
  {
    id: 5,
    label: "Disapproved",
    value: "Disapproved",
  },
  {
    id: 6,
    label: "Downloaded",
    value: "Downloaded",
  },
];

export const customStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "#f8f8ff",
    border: "1px solid #656eb2",
    boxShadow: "0px 17px 24px -14px rgb(121 137 255 / 55%)",
    borderRadius: "12px",
    height: "80px",
    backgroundSize: "20px 15px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};

export const defaultTableOption = {
  isInitialized: true,
  currentRowsPerPage: 10,
  data: [],
  isLoading: false,
  page: 1,
  searchValue: "",
  totalRows: 0,
  offset: 0,
  sort: {
    field: "",
    direction: "",
  },
};

export const regionSelectStyles = {
  control: (base) => ({
    ...base,
    width: "100%",
    border: "0.5px solid #5A69DA",
    borderRadius: "3px",
    padding: "0",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export const statusSelectStyles = {
  control: (base) => ({
    ...base,
    width: "100%",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    display: state.isFocused ? "block" : "none",
    color: "white",
  }),
  valueContainer: (base) => ({
    ...base,
    textAlign: "center",
  }),
};

export const attendanceStatusSelectStyles = {
  control: (base) => ({
    ...base,
    width: "100%",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    textAlign: "center",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Ensures dropdown appears in front of everything
  }),
};

export const searchInputStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export const batchStatusStyle = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? "white" : "white",
    backgroundColor: state.isClicked ? "#2534A3" : "initial",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "#4252C9",
    borderRadius: "3px",
    borderColor: "#4252C9",
    borderStyle: "none",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
    textTransform: "uppercase",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
  }),
};

export const statusFilterStyle = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? "white" : "white",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base) => ({
    ...base,
    borderRadius: "3px",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    textTransform: "uppercase",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "white",
      borderColor: "#4252C9",
      borderRadius: "6px",
      borderStyle: "solid",
      borderWidth: "2px",
      padding: "2px 5px",
      display: "flex",
      alignItems: "center",
    };
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "black",
    ":hover": {
      backgroundColor: "#ffffff",
      color: "#ff0000",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "white",
      color: state.isSelected ? "#4252C9" : provided.color,
      fontWeight: "600",
    };
  },
};

export const customProjectStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "#f8f8ff",
    border: "1px solid #656eb2",
    boxShadow: "0px 17px 24px -14px rgb(121 137 255 / 55%)",
    borderRadius: "12px",
    height: "80px",
    backgroundSize: "20px 15px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};

export const addProjectDropdownStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "#F5F5F7",
    borderRadius: "16px",
    height: "60px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};

export const addProjectSearchInputStyle = {
  control: (base) => ({
    ...base,
    backgroundColor: "#f5f5f7",
    borderRadius: "16px",
    height: "60px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
};

export const accountCustomStyle = {
  control: (base) => ({
    ...base,
    backgroundColor: "#F5F5F7",
    borderRadius: "16px",
    height: "60px",
    overflow: "auto",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        alt="dropdownicon"
        src={dropdownIcon}
        height="24px"
      />
    </components.DropdownIndicator>
  );
};

export const DropdownIndicator2 = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        alt="dropdownicon"
        src={dropdown}
        height="24px"
      />
    </components.DropdownIndicator>
  );
};

export const SelectDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        alt="regionDropdown"
        src={regionDropdown}
        height="18px"
      />
    </components.DropdownIndicator>
  );
};

export const MultiSelectorStyle = {
  control: (base) => ({
    ...base,
    backgroundColor: "#F5F5F7",
    borderRadius: "16px",
    height: "60px",
    overflow: "auto",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};

export const NewsMultiSelectorStyle = {
  control: (base) => ({
    ...base,
    backgroundColor: "#F5F5F7",
    borderRadius: "16px",
    maxHeight: "200px",
    minHeight: "60px",
    overflow: "auto",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "5px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};

export const NewsCustomFormStyle = {
  control: (base) => ({
    ...base,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "none",
    boxShadow: "none",
    overflow: "auto",
    height: "50px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: "2",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: "none",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    color: "inherit",
  }),
};
