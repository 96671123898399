import React, { useEffect, useState } from "react";
import { splitArrayToChunks } from "utility/helpers";
import { Row, Col, Input } from "reactstrap";
import AgencyContainer from "components/customs/agencyContainer";
import _ from "lodash";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { fetchAllAgencies } from "services/services.projects";

const Main = () => {
  const dateRange = useSelector((state) => state.dateRange);
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { dateFrom } = dateRange || {};

  const getFormattedData = () => {
    const newData = data.length >= 3 ? splitArrayToChunks(data, 3) : data;
    let resultData = [];

    resultData =
      newData.length >= 3
        ? newData?.length !== 0 &&
          newData.map((datum) => {
            return (
              datum &&
              datum?.length !== 0 &&
              datum?.map((d) => {
                return {
                  photo: d?.photo,
                  label: d?.name,
                  link: `/projects/${d?.id}`,
                  pendingCount: d?.pendingCount,
                  requestedCount: d?.requestedCount,
                  acronym: d?.acronym,
                  id: d?.id,
                };
              })
            );
          })
        : newData?.map((d) => {
            return {
              photo: d?.photo,
              label: d?.name,
              link: `/projects/${d?.id}`,
              pendingCount: d?.pendingCount,
              requestedCount: d?.requestedCount,
              acronym: d?.acronym,
              id: d?.id,
            };
          });

    return resultData;
  };

  const fetchData = () => {
    try {
      setIsLoading(true);

      fetchAllAgencies({
        callback: (projects) => {
          if (projects?.length === 1) navigate(`/projects/${projects[0]?.id}`);
          setData(projects);
          setIsLoading(false);
        },
        dateRange,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleSearch = _.debounce((evt) => {
    const term = evt.target.value;

    if (term?.length === 0) {
      setFilteredData(initialData);

      return;
    }

    const newArray = initialData.flat();
    const result = [];

    newArray.forEach((agency) => {
      if (
        agency?.acronym?.toLowerCase()?.includes(term?.toLowerCase()) ||
        agency?.label?.toLowerCase()?.includes(term?.toLowerCase())
      ) {
        result.push(agency);
      }
    });

    if (result?.length !== 0) {
      if (result?.length >= 3) {
        const newData = splitArrayToChunks(result, 3);
        setFilteredData(newData);

        return;
      }

      setFilteredData(result);

      return;
    }

    setFilteredData([]);
  }, 1500);

  useEffect(() => {
    fetchData();
  }, [dateFrom]);

  useEffect(() => {
    if (data?.length !== 0) {
      const result = getFormattedData();
      setFilteredData(result);
      setInitialData(result);
    }
  }, [data]);

  return (
    <div className="m-0 p-0 project-list">
      <div className="d-flex justify-content-center text-center my-5 px-4">
        <h1>{"Projects and Programs (Select an Agency)"}</h1>
      </div>

      <div className="d-flex justify-content-center text-center">
        <Col
          md="7"
          lg="5"
          xl="4"
        >
          <Input
            placeholder="Search"
            onChange={handleSearch}
            disabled={isLoading}
          />
        </Col>
      </div>

      <Row className="agency-container px-5 m-0 d-flex gx-4">
        {filteredData?.length !== 0 &&
          filteredData?.map((datum) => {
            return (
              <Col
                key={datum?.id}
                xs="auto"
              >
                {Array.isArray(datum) ? (
                  datum?.map((d) => {
                    return (
                      <Col
                        key={datum?.id}
                        className="my-4 d-flex justify-content-center"
                      >
                        <AgencyContainer
                          key={datum?.id}
                          {...d}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <Col
                    key={datum?.id}
                    className="my-4 d-flex justify-content-center"
                  >
                    <AgencyContainer
                      key={datum?.id}
                      {...datum}
                    />
                  </Col>
                )}
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default Main;
