import ImgContainer from "components/common/img";
import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Card,
  Button,
  Col,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { Tooltip } from "react-tooltip";
import CustomSelect from "components/customs/dropdown";
import dropdownIcon from "assets/img/Ellipse 66.png";
import SearchBarComponent from "components/customs/searchbarV2";
import CustomDataTable from "components/customs/table";
import { getUserDetails, APP_URL } from "config";
import {
  getFormattedContactNumber,
  getFormattedDateString,
  getFormattedNumber,
  getFormattedOptions,
  getModifiedObject,
  getRegionOptions,
  hasPermission,
  Toast,
} from "utility/helpers";
import dropdownIconRotated from "assets/img/dropdown-rotated.png";
import FormGenertor from "components/customs/form-generator/input";
import { Edit2, Eye, Info, Trash2 } from "react-feather";
import CommentsModal from "components/customs/comments-modal";
import StackedProgressBar from "components/customs/stacked-progress-bar";
import Swal from "sweetalert2";
import { genericGet, genericPost, uploadFile } from "services/services.general";
import {
  fetchAgencyBudgetAllocation,
  fetchComments,
  fetchCongressmanDetails,
  fetchCongressmanProjects,
  fetchRegions,
  fetchCommiteeDetails,
  fetchDistricts,
  editCongDetails,
  fetchAllUpdateLogs,
} from "services/services.congressman";
import {
  deleteRecipient,
  recipientBatchUpdate,
} from "services/services.projects";
import {
  defaultTableOption,
  statuses,
  customStyles,
  regionSelectStyles,
  statusSelectStyles,
  DropdownIndicator,
  SelectDropdownIndicator,
  congRoles,
  canEditRoles,
  statusFilterStyle,
} from "utility/constants";
import editIcon from "assets/img/editIcon.svg";
import InputGroup from "components/customs/input-group";
import moment from "moment";
import { fetchPartylists } from "services/services.affiliation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons";
import usePersistentCountdownTimer from "hooks/usePersistentTimer";
import { fetchPendingProjects } from "../../../redux/actions/projects";

const agencyWithSpecialForms = [3, 8, 27, 14];

const statusFilter = [
  { value: "Requested", label: "Requested" },
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Endorsed" },
  { value: "Processed", label: "Processed" },
  { value: "Downloaded", label: "Downloaded" },
  { value: "Disapproved", label: "Pls See Comment" },
];

const allowedBumpStatus = ["Approved", "Pending", "Requested"];

const programsRowPerPage = 100;

const Main = () => {
  const { id, congID } = useParams();
  const location = useLocation();

  const agency = location?.state?.agencyId || null;

  const [congDetails, setCongDetails] = useState({});
  const [selectorOptions, setSelectorOptions] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [totalBudget, setTotalBudget] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [recipientComments, setRecipientComments] = useState([]);
  const [editingCongressman, setEditingCongressman] = useState("");
  const [regions, setRegions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempCongDetails, setTempcongDetails] = useState({});
  const [regionOptions, setRegionOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [isCommitteShown, setIsCommitteShown] = useState(false);
  const [committeeDetails, setCommitteeDetails] = useState({});
  const [editingRowId, setEditingRowId] = useState();
  const [initialProjects, setInitialProjects] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  const [selectedStatusFilter, setSelectedStatusFilter] = useState();

  const [projects, setProjects] = useState([]);
  const [logs, setLogs] = useState([]);

  const navigate = useNavigate();

  let changeSearchTimeoutID;

  const dispatch = useDispatch();

  const role = getUserDetails()?.type;

  const isRoleCongressman = congRoles.includes(role);

  const { timer, toggleTimer } = usePersistentCountdownTimer();

  const dateRange = useSelector((state) => state.dateRange);
  const { dateFrom, dateTo } = dateRange || {};

  const toggleModal = () => setModalVisible(!modalVisible);

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;
      // reset editing row
      setEditingRowId();

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const onChangePage = (page) => {
    // reset editing row
    setEditingRowId();

    setTableOption({
      ...tableOption,
      page,
      offset: programsRowPerPage * page - 100,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    // reset editing row
    setEditingRowId();

    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column, sortDirection) => {
    // reset editing row
    setEditingRowId();

    setTableOption({
      ...tableOption,
      sort: {
        field: column.identifier,
        direction: sortDirection,
      },
    });
  };

  const handleSelectOnChange = (option) => {
    setSelectedAgency(option);
    setSelectedDropdown(option);
  };

  const handleCancelClicked = (index) => {
    setEditingRowId("");
    setEditingCongressman("");

    const newData = [...tableOption.data];
    newData[index] = initialProjects[index];

    setTableOption({
      ...tableOption,
      data: newData,
    });
  };

  const getStatusOptionsPosition = () => {
    if (initialProjects?.length === 1) {
      return "bottom";
    }

    if (
      editingRowId === initialProjects.length - 1 &&
      initialProjects?.length > 3
    ) {
      return "top";
    }

    return "bottom";
  };

  const canEditRow = (status, isEdited) => {
    let canEdit = false;

    if (
      role === "Editor" &&
      (status === "Pending" ||
        status === "Requested" ||
        status === "Disapproved")
    ) {
      canEdit = true;
    } else if (role === "Director" && status !== "Not Eligible") {
      canEdit = true;
    } else if (
      role === "Agency" &&
      status !== "Disapproved" &&
      status !== "Pending" &&
      status !== "Not Eligible"
    ) {
      canEdit = true;
    } else if (
      congRoles.includes(role) &&
      (status === "Requested" ||
        (status === "For Resubmission" && !isEdited) ||
        status === "Disapproved")
    ) {
      canEdit = true;
    }

    return canEdit;
  };

  const cannotEditStatus = (status) => {
    let cannotEdit = true;

    if (
      role === "Director" &&
      status !== "Processed" &&
      status !== "In-Progress" &&
      status !== "Downloaded"
    ) {
      cannotEdit = false;
    } else if (
      role === "Agency" &&
      status !== "Disapproved" &&
      status !== "Pending"
    ) {
      cannotEdit = false;
    } else if (
      role === "Editor" &&
      (status === "Requested" ||
        status === "Pending" ||
        status === "Disapproved")
    ) {
      cannotEdit = false;
    }

    return cannotEdit;
  };

  const getStatusOptions = (status) => {
    let options = statuses;

    if (role === "Director") {
      if (status !== "Approved") {
        options = options?.filter(
          (option) =>
            option.value !== "Processed" &&
            option.value !== "In-Progress" &&
            option.value !== "Downloaded" &&
            option.value !== "Requested" &&
            option.value !== "For Resubmission" &&
            option.value !== "Not Eligible"
        );
      } else {
        options = options?.filter(
          (option) =>
            option.value !== "Processed" &&
            option.value !== "In-Progress" &&
            option.value !== "Downloaded" &&
            option.value !== "Requested"
        );
      }
    }

    if (role === "Agency") {
      if (status === "Approved") {
        options = options?.filter(
          (option) =>
            option.value === "Processed" ||
            option.value === "Downloaded" ||
            option.value === "For Resubmission" ||
            option.value === "Not Eligible"
        );
      } else {
        options = options?.filter(
          (option) =>
            option.value === "Processed" ||
            option.value === "Downloaded" ||
            option.value === "Approved"
        );
      }
    }

    if (role === "Editor") {
      options = options?.filter(
        (option) =>
          option.value === "Requested" ||
          option.value === "Pending" ||
          option.value === "Disapproved"
      );
    }

    return options;
  };

  const getSelectorOptions = (rawData) => {
    if (rawData?.length !== 0) {
      const options =
        rawData?.map((select) => ({
          ID: select?.id,
          Name: select?.name,
          acronym: select?.acronym,
          label: (
            <h5 className="font-weight-bold mb-0">{`${select?.name} (${select?.acronym})`}</h5>
          ),
          photo: select?.photo,
        })) || [];

      if (agency) {
        const option = options?.find((op) => op.ID === agency);

        if (option) {
          setSelectedAgency(option);
        }
      } else {
        setSelectedAgency(options[0]);
        setSelectedDropdown(options[0]);
      }

      setSelectorOptions(options);
    }
  };

  const fetchProjects = () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    const params = {
      limit: programsRowPerPage,
      offset: tableOption.offset,
      search: tableOption.searchValue,
      column: tableOption.sort.field,
      order: tableOption.sort.direction,
      startDate: dateFrom || "",
      endDate: dateTo || "",
    };

    if (role !== "Agency") {
      params.status = selectedStatusFilter?.map((item) => item.value).join(";");
    }

    fetchCongressmanProjects({
      url: `/congressman/${congID}/agencies/${selectedAgency?.ID}/recipients`,
      params,
      callback: (data) => {
        setTableOption({
          ...tableOption,
          isLoading: false,
          totalRows: data?.count,
          data: data?.rows,
        });
        setInitialProjects(data?.rows);
      },
    });
  };

  const fetchData = () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    fetchCongressmanDetails({
      url: `/congressman/${congID}`,
      callback: (data) => {
        const newData = data;
        newData.cosContact = getFormattedContactNumber(newData.cosContact);
        newData.contact = getFormattedContactNumber(newData.contact);
        setCongDetails(data);
        setTempcongDetails(newData);

        if (data.Projects.length === 0) {
          setTableOption({
            ...tableOption,
            isLoading: false,
          });
        }
      },
    });
  };

  const handleChangeFilter = (selected) => {
    setSelectedStatusFilter(selected || []);
  };

  const handleSaveStatusChange = (data, index) => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    const initialData = initialProjects[index];
    const newData = {};

    // eslint-disable-next-line array-callback-return
    Object.keys(initialData)?.map((obj) => {
      if (initialData[obj] !== data[obj]) {
        if (obj === "budget_allocation") {
          newData.budget = data[obj];
        } else if (obj === "implementing") {
          newData.implementingAgency = data[obj].id;
        } else if (obj === "status") {
          //
        } else {
          newData[obj] = data[obj];
        }
      }
    });

    const finalData = {};
    finalData.status = data?.status;

    finalData.recipients = [
      {
        recipientId: data?.id,
        ...newData,
      },
    ];

    recipientBatchUpdate({
      url: "/recipient/batch-update/status",
      formData: finalData,
      callback: ({ isSuccess, msg }) => {
        if (isSuccess) {
          fetchProjects();
          if (role === "Director") dispatch(fetchPendingProjects());

          const text = msg.replace(/\n/g, "<br>");

          Swal.fire({
            title: text.includes("<br>") ? "" : "Success!",
            icon: "success",
            html: text,
            customClass: text.includes("<br>")
              ? { container: "sms-notif" }
              : null,
          });
        } else {
          handleCancelClicked(index);
        }
      },
    });
  };

  const handleRemarks = (text, recipientId, url) => {
    genericPost({
      url: "/comment",
      formData: {
        text,
        recipientId,
        newsId: null,
      },
      callback: () => {
        //
      },
    });

    genericPost({
      url,
      formData: {
        remarks: text,
        recipientId,
      },
      callback: () => {
        //
      },
    });
  };

  const handleDeleteAction = (row, idx) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: "Are you sure you want to delete this requested budget?",
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecipient({
          params: row?.id,
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully deleted recipient.",
              });
              fetchProjects();
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      } else if (idx !== undefined && idx !== null) {
        handleSaveStatusChange(row, idx);
      }
    });
  };

  // eslint-disable-next-line consistent-return
  const handleStatusChange = async (index, option) => {
    const newData = [...tableOption.data];
    const oldValue = newData[index].status;
    const newValue = option.value;

    const oldStatus = statuses.find(
      (status) => status.value === newData[index].status
    ).label;

    if (oldValue.toLowerCase() !== newValue.toLowerCase()) {
      try {
        const result = await Swal.fire({
          title: "Warning",
          // eslint-disable-next-line max-len
          text: `Are you sure you want to change the status from ${oldStatus} to ${option.label}?`,
          icon: "warning",
          confirmButtonText: "OK",
          showCancelButton: true,
        });

        if (result.isConfirmed) {
          newData[index] = {
            ...newData[index],
            status: newValue,
          };

          setTableOption({
            ...tableOption,
            data: newData,
          });

          if (newValue === "For Resubmission" || newValue === "Not Eligible") {
            const { value: text } = await Swal.fire({
              input: "textarea",
              inputLabel: "Remarks",
              inputPlaceholder: "Type your remarks here...",
              inputAttributes: {
                "aria-label": "Type your remarks here",
              },
              showCancelButton: true,
            });

            if (text) {
              newData[index] = {
                ...newData[index],
                remarks: text,
              };

              const url =
                newValue === "For Resubmission"
                  ? "/recipient/send-resubmission-notification"
                  : "/recipient/send-not-eligible-notification";
              handleRemarks(text, newData[index].id, url);
            } else return;
          }

          if (newValue === "Not Eligible" && role === "Director") {
            const res = await Swal.fire({
              title: "Warning",
              text: "Not Eligible allocations are opt to be deleted. Do you wish to proceed?",
              icon: "info",
              confirmButtonText: "OK",
              showCancelButton: true,
            });

            if (res.isConfirmed) {
              handleDeleteAction(newData[index], index);

              return;
            }
          }

          handleSaveStatusChange(newData[index], index);
        } else {
          newData[index] = {
            ...newData[index],
            status: oldValue,
          };

          setTableOption({
            ...tableOption,
            data: newData,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const fetchCommitteeTable = () => {
    fetchCommiteeDetails({
      params: congID,
      callback: (data) => {
        setCommitteeDetails(data);
      },
    });
  };

  const fetchRecipientMsgs = (rowId) => {
    fetchComments({
      params: rowId,
      callback: (data) => {
        setRecipientComments(data);
      },
    });
  };

  const fetchBudgetAllocation = () => {
    fetchAgencyBudgetAllocation({
      params: {
        cong: congID,
        agency: selectedDropdown?.ID,
        startDate: dateFrom || "",
        endDate: dateTo || "",
      },
      callback: (data) => {
        const newData = data?.projects?.length !== 0 ? data?.projects : [];

        const total = newData?.reduce((sum, proj) => {
          return sum + proj.allocatedBudget;
        }, 0);

        setTotalBudget(total);

        const projs = newData?.map((proj) => {
          return {
            id: proj?.id,
            name: proj?.name,
            value: proj.allocatedBudget,
            budget:
              proj.allocatedBudget !== 0 || proj.allocatedBudget !== null
                ? (proj.allocatedBudget / total) * 100
                : 0,
          };
        });

        setProjects(projs);
      },
    });
  };

  const fetchAllAffiliation = () => {
    fetchPartylists({
      callback: (Data) => {
        if (Array.isArray(Data) && Data?.length !== 0) {
          const options = getFormattedOptions("acronym", Data);
          setParties(options);
        }
      },
    });
  };

  const formatRegions = () => {
    fetchRegions({
      callback: (Data) => {
        if (Array.isArray(Data) && Data?.length !== 0) {
          const options = getFormattedOptions("acronym", Data);
          setRegions(options);

          const regionalOptions = getRegionOptions(Data);
          setRegionOptions(regionalOptions);
        }
      },
    });
  };

  const fetchAllDistrcit = () => {
    fetchDistricts({
      callback: (Data) => {
        if (Array.isArray(Data) && Data?.length !== 0) {
          setDistricts(Data);
        }
      },
    });
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".cong-img");

        const fileName = congDetails
          ? `${
              congDetails.id
            }-${congDetails.lastName.toLocaleLowerCase()}-${Date.now()}`
          : `${congDetails.lastName.toLocaleLowerCase()}-${Date.now()}`;

        setTempcongDetails((prevState) => ({
          ...prevState,
          photo: file,
          photoName: fileName,
        }));
        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  const handleBumpIconOnClicked = async (congId, status) => {
    const result = await Swal.fire({
      title: "Warning",
      text: "Would you like to bump this allocation?",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      setTableOption({
        ...tableOption,
        isLoading: true,
      });

      toggleTimer(congId);

      genericPost({
        url: "/comment",
        formData: {
          text: "bumped this notification.",
          recipientId: congId,
        },
        callback: () => {
          //
        },
      });

      genericPost({
        url: "/recipient/bump-allocation-notification",
        formData: {
          status,
          recipientId: congId,
        },
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            fetchProjects();
            fetchComments(congId);
          }
        },
      });
    }
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status }) => (
        <div
          className={`${
            status === "Pending" || status === "Requested" ? "is-pending" : ""
          }`}
        />
      ),
    },
    {
      name: "",
      selector: (row) => row?.badge,
      sortable: false,
      width: "20px",
      id: "badge",
      // eslint-disable-next-line max-len
      // eslint-disable-next-line react/no-unstable-nested-components, consistent-return
      cell: ({ status }, index) => {
        if (status === "Pending" || status === "Requested") {
          return (
            <Badge
              className={`badge ${
                // eslint-disable-next-line no-nested-ternary
                status === "Pending"
                  ? "is-pending"
                  : status === "Requested"
                  ? "is-requested"
                  : ""
              }`}
              pill
            >
              <div>{index}</div>
            </Badge>
          );
        }
      },
    },
    {
      name: "ID",
      selector: (row) => row?.id,
      identifier: "id",
      sortable: true,
      minWidth: "max-content",
      center: true,
      style: { paddingLeft: 0, paddingRight: 0 },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <p
          className={`mb-0 ${
            row.status === "Pending" || row.status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {row.id}
        </p>
      ),
    },
    {
      name: "Program",
      selector: (row) => row?.project,
      identifier: "project",
      grow: 2,
      sortable: true,
      minWidth: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) =>
        editingRowId !== index ? (
          <p
            className={`mb-0 ${
              row.status === "Pending" || row.status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {row.project}
          </p>
        ) : (
          <FormGenertor
            type="text"
            onChange={(e) => {
              const { value } = e.target;

              const newData = [...tableOption.data];

              newData[index] = {
                ...row,
                project: value,
              };

              setTableOption({
                ...tableOption,
                data: newData,
              });
            }}
            value={row.project}
          />
        ),
    },
    {
      name: "Budget",
      selector: (row) => row?.budget_allocation,
      identifier: "budget_allocation",
      grow: 2,
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: (row, index) =>
        editingRowId !== index ? (
          <p
            className={`mb-0 ${
              row.status === "Pending" || row.status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {`PHP ${Number(row.budget_allocation)?.toLocaleString()}`}
          </p>
        ) : (
          <FormGenertor
            className="form-control"
            type="number"
            onChange={(e) => {
              const { value } = e;

              const newData = [...tableOption.data];

              newData[index] = {
                ...row,
                // eslint-disable-next-line camelcase
                budget_allocation: Number(value),
              };

              setTableOption({
                ...tableOption,
                data: newData,
              });
            }}
            value={Number(row.budget_allocation)}
          />
        ),
    },
    {
      name: "Type",
      selector: (row) => row?.allocationType,
      identifier: "allocationType",
      sortable: true,
      minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, allocationType }) => {
        return (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {allocationType.id === 2 ? "ATC" : allocationType.name}
          </p>
        );
      },
    },
    {
      name: "Other Info",
      selector: (row) => row?.allocationType,
      identifier: "allocationType",
      sortable: true,
      minWidth: "170px",
      grow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({
        status,
        allocationType,
        allocationName,
        allocationContactName,
        institution,
        otherInfo,
      }) => {
        let renderOtherInfo = null;

        if (Number(selectedAgency?.ID) === 3 && allocationType.id === 1) {
          renderOtherInfo = otherInfo || "";
        } else if (
          agencyWithSpecialForms.includes(Number(selectedAgency?.ID)) &&
          allocationType.id === 1
        ) {
          renderOtherInfo = institution?.name;
        } else if (allocationType.id === 2) {
          renderOtherInfo = allocationName;
        } else if (allocationType.id === 3) {
          renderOtherInfo = allocationContactName;
        }

        return (
          <p
            className={`m-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {renderOtherInfo}
          </p>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      identifier: "status",
      grow: 2,
      sortable: true,
      minWidth: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status }, index) => (
        <CustomSelect
          className={`${
            status === "Pending" || status === "Requested" ? "is-pending" : ""
          } ${cannotEditStatus(status) ? "" : "pointer"}`}
          isSearchable={false}
          isDisabled={cannotEditStatus(status)}
          onChange={(option) => {
            handleStatusChange(index, option);
          }}
          value={statuses.find((stat) => stat?.value === status)}
          options={getStatusOptions(status)}
          styles={statusSelectStyles}
          classNamePrefix="select"
          menuPlacement={getStatusOptionsPosition()}
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(option) => {
            return option?.displayLabel;
          }}
        />
      ),
    },
    {
      name: "Implementing Agency",
      selector: (row) => row?.implementing,
      identifier: "implementing",
      grow: 2,
      sortable: false,
      minWidth: "165px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => (
        <CustomSelect
          className={`region ${
            row.status === "Pending" || row.status === "Requested"
              ? "is-pending"
              : ""
          }`}
          isSearchable
          isDisabled={
            editingRowId !== index && hasPermission("recipient-update")
          }
          onChange={(option) => {
            const newData = [...tableOption.data];

            newData[index] = {
              ...row,
              implementing: {
                ...newData[index].implementing,
                id: option?.value,
              },
            };

            setTableOption({
              ...tableOption,
              data: newData,
            });
          }}
          value={regions.find(
            (region) => region.value === row.implementing?.id
          )}
          options={regions}
          styles={regionSelectStyles}
          classNamePrefix="select"
          components={{
            DropdownIndicator: SelectDropdownIndicator,
          }}
          menuPlacement={getStatusOptionsPosition()}
          maxMenuHeight="150px"
        />
      ),
    },
    {
      name: "Province",
      selector: (row) => row?.province,
      identifier: "province",
      id: "province",
      sortable: true,
      minWidth: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, province }) => (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {province?.name}
        </p>
      ),
    },
    {
      name: "Municipality",
      selector: (row) => row?.municipality,
      identifier: "municipality",
      id: "municipality",
      sortable: true,
      minWidth: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, municipality }) => (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {municipality?.name}
        </p>
      ),
    },
    {
      name: "Date Requested",
      selector: (row) => row?.dateRequested,
      identifier: "dateRequested",
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, dateRequested }) => (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {getFormattedDateString(dateRequested, "MMM-dd-yyyy")}
        </p>
      ),
    },
    {
      name: "Date Approved",
      selector: (row) => row?.dateApproved,
      identifier: "dateApproved",
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ dateApproved, status }) =>
        dateApproved ? (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {getFormattedDateString(dateApproved, "MMM-dd-yyyy")}
          </p>
        ) : (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {"-"}
          </p>
        ),
    },
    {
      name: "Remarks",
      selector: (row) => row?.hasComments,
      sortable: false,
      minWidth: "160px",
      // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
      cell: ({ status, hasComments, id, unreadMsg }) => (
        <div
          className={`comment-section p-0 ${
            status === "Pending" || status === "Requested" ? "is-pending" : ""
          }`}
        >
          {hasComments === 0 && hasPermission("comment-view") && (
            <Button
              className="add-comment-btn"
              size="sm"
              onClick={() => {
                setEditingCongressman(id);
                fetchRecipientMsgs(id);
                // eslint-disable-next-line no-use-before-define
                toggleModal();
              }}
            >
              {"Add Comment"}
            </Button>
          )}

          {hasComments === 1 && hasPermission("comment-create") && (
            <>
              {status === "Disapproved" && unreadMsg !== 0 && (
                <Badge
                  className="unread-msgs"
                  pill
                >
                  {unreadMsg === 0 ? null : unreadMsg}
                </Badge>
              )}

              <Button
                className="view-comment-btn"
                size="sm"
                onClick={() => {
                  setEditingCongressman(id);
                  fetchRecipientMsgs(id);
                  // eslint-disable-next-line no-use-before-define
                  toggleModal();
                }}
              >
                {"View Comment"}
              </Button>
            </>
          )}
        </div>
      ),
    },
    {
      name: "Updated By",
      selector: (row) => row?.updatedBy,
      identifier: "updatedBy",
      grow: 1,
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, updatedBy }) =>
        updatedBy && updatedBy !== null ? (
          <p className="mb-0">{updatedBy}</p>
        ) : (
          <p
            className={`mb-0  ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {"-"}
          </p>
        ),
    },
    {
      name: "Actions",
      selector: (row) => row?.id,
      sortable: false,
      width: "150px",
      center: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => {
        const isBumpIconDisabled =
          timer && timer?.find((time) => time?.id === row.id && time?.isActive);

        return (
          <>
            {editingRowId !== index &&
              canEditRow(row?.status, row.isEdited) && (
                <>
                  <Edit2
                    size={16}
                    id={`edit-row--${index}`}
                    color="#4252C9"
                    className="mr-05"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/representatives/${id}/cong/${congID}/recipient`,
                        {
                          state: {
                            row,
                            agency: selectedAgency?.ID,
                            program: congDetails?.Projects.find(
                              (project) => project.name === row?.project
                            )?.id,
                            action: "edit",
                          },
                        }
                      );
                    }}
                  />
                  <UncontrolledTooltip
                    placement="left"
                    target={`edit-row--${index}`}
                  >
                    {"Edit"}
                  </UncontrolledTooltip>
                </>
              )}

            <Eye
              size={16}
              color="#4252C9"
              className="mr-05"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/representatives/${id}/cong/${congID}/recipient`, {
                  state: {
                    row,
                    agency: selectedAgency?.ID,
                    program: congDetails?.Projects.find(
                      (project) => project.name === row?.project
                    )?.id,
                    action: "view",
                  },
                })
              }
              id={`view-row--${index}`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`view-row--${index}`}
            >
              {"View"}
            </UncontrolledTooltip>

            {allowedBumpStatus.includes(row.status) && (
              <>
                <FontAwesomeIcon
                  id={`bump-row--${index}`}
                  icon={faCommentDollar}
                  color="#4252C9"
                  size="xl"
                  className="mr-05"
                  style={
                    !isBumpIconDisabled
                      ? { cursor: "pointer" }
                      : { cursor: "initial", pointerEvents: "none" }
                  }
                  disabled={isBumpIconDisabled}
                  onClick={() => handleBumpIconOnClicked(row.id, row.status)}
                />

                <UncontrolledTooltip
                  placement="top"
                  target={`bump-row--${index}`}
                >
                  {"Bump"}
                </UncontrolledTooltip>
              </>
            )}

            {editingRowId !== index &&
              row.status === "Requested" &&
              isRoleCongressman && (
                <>
                  <Trash2
                    size={16}
                    id={`delete-row--${index}`}
                    onClick={() => handleDeleteAction(row)}
                    color="#4252C9"
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`delete-row--${index}`}
                  >
                    {"Delete"}
                  </UncontrolledTooltip>
                </>
              )}
          </>
        );
      },
    },
  ];

  const validate = () => {
    if (
      selectedDistrict === null &&
      selectedRegion !== null &&
      tempCongDetails?.District?.RegionId !== selectedRegion?.id
    ) {
      return "Required district";
    }

    if (
      selectedDistrict !== null &&
      selectedRegion === null &&
      selectedDistrict?.RegionId !== tempCongDetails?.District?.RegionId
    ) {
      return "Required region";
    }

    if (
      tempCongDetails?.contact &&
      !/^(639)\d{9}$/.test(tempCongDetails?.contact)
    ) {
      return "Invalid congressman mobile number";
    }

    if (
      tempCongDetails?.cosContact &&
      !/^(639)\d{9}$/.test(tempCongDetails?.cosContact)
    ) {
      return "Invalid cos mobile number";
    }

    return true;
  };

  // revert changes
  const handleFailedCongUpdate = () => {
    setIsEditMode(false);
    setTempcongDetails(congDetails);

    const region = regionOptions.find(
      (__region) => __region.id === congDetails.District.Region.id
    );

    setSelectedRegion(region);

    const district = districts.find(
      (__district) => __district.id === congDetails.DistrictId
    );
    setSelectedDistrict(district);

    const partylist = parties.find((party) => party.id === congDetails.PartyId);
    setSelectedParty(partylist);

    setTableOption({
      ...tableOption,
      isLoading: false,
    });
  };

  const fetchLogs = async () => {
    await fetchAllUpdateLogs({
      param: congID,
      callback: (data) => {
        if (data) {
          setLogs(data);
        }
      },
    });
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: tempCongDetails.photo,
      fileName: tempCongDetails.photoName,
      onProgress: () => {
        //
      },
      callback: (data) => {
        if (data) fileName = data;
      },
    });

    return fileName;
  };

  const handleSaveButtonClicked = async () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const data = {
        committeeChairmanship: tempCongDetails.committeeChairmanship,
        DistrictId: selectedDistrict?.id || tempCongDetails.DistrictId,
        PartyId: selectedParty?.id || tempCongDetails.PartyId,
        RegionId: selectedRegion?.id || tempCongDetails.District.RegionId,
        termOfOffice: tempCongDetails.termOfOffice,
        birthdate: tempCongDetails.birthdate,
        contact: tempCongDetails.contact?.slice(2),
        cosName: tempCongDetails.cosName,
        cosContact: tempCongDetails.cosContact?.slice(2),
        // eslint-disable-next-line max-len
        fullName: `HON. ${tempCongDetails.firstName} ${tempCongDetails.middleInitial} ${tempCongDetails.lastName}`,
        firstName: tempCongDetails.firstName,
        middleInitial: tempCongDetails.middleInitial,
        lastName: tempCongDetails.lastName,
      };

      const initialData = {
        committeeChairmanship: congDetails.committeeChairmanship,
        DistrictId: congDetails.DistrictId,
        PartyId: congDetails.PartyId,
        RegionId: congDetails.District.RegionId,
        termOfOffice: congDetails.termOfOffice,
        birthdate: congDetails.birthdate,
        contact: congDetails.contact?.slice(2),
        cosName: congDetails.cosName,
        cosContact: congDetails.cosContact?.slice(2),
        fullName: congDetails.fullName,
        firstName: congDetails.firstName,
        middleInitial: congDetails.middleInitial,
        lastName: congDetails.lastName,
        photo: congDetails.photo,
      };

      if (typeof tempCongDetails.photo !== "string") {
        const fileName = await uploadImage();
        data.photo = fileName;
      }

      const newData = await getModifiedObject(initialData, data);

      await editCongDetails({
        url: `${APP_URL}/congressman/${congID}`,
        formData: newData,
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            fetchProjects();
            setIsEditMode(false);
            fetchLogs();

            Toast({
              type: 1,
              content: "Successfully edited cong details",
            });
          } else {
            handleFailedCongUpdate();

            Toast({
              type: 4,
              content: "An error occured.",
            });
          }
        },
      });
    } else {
      handleFailedCongUpdate();

      setTableOption({
        ...tableOption,
        isLoading: false,
      });

      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const getIndex = (property) => logs?.findIndex((log) => log.data[property]);

  const fundSourceColumn = {
    name: "Fund Source",
    selector: (row) => row?.fundSource,
    identifier: "fundSource",
    sortable: true,
    minWidth: "150px",
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ status, fundSource }) => {
      return (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {fundSource}
        </p>
      );
    },
  };

  const editorNotesColumn = {
    name: "Editor Notes",
    selector: (row) => row?.editorNotes,
    identifier: "editorNotes",
    sortable: true,
    minWidth: "150px",
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ status, editorNotes }) => {
      return (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {editorNotes
            ? editorNotes
                ?.split(";")
                ?.map((notes) => notes)
                ?.join(", ")
            : ""}
        </p>
      );
    },
  };

  if (selectedAgency?.ID === 14 && role && canEditRoles.includes(role)) {
    columns.splice(10, 0, fundSourceColumn);
  }

  if (role && canEditRoles.includes(role)) {
    columns.splice(12, 0, editorNotesColumn);
  }

  const hasCongCosAccess = (congId) => {
    genericGet({
      url: `auth/has-access?congressman=${congId}`,
      callback: (data) => {
        setHasAccess(data.data);
      },
    });
  };

  useEffect(() => {
    if (congDetails?.Projects && congDetails?.Projects?.length !== 0) {
      let options = [];

      // eslint-disable-next-line array-callback-return
      congDetails?.Projects?.map((proj) => {
        if (
          proj?.Agency &&
          !options.find((option) => option?.id === proj?.Agency?.id)
        ) {
          options.push(proj?.Agency);
        }
      });

      options = getSelectorOptions(options);
    }
  }, [congDetails]);

  useEffect(() => {
    if (selectedAgency !== null) {
      fetchProjects();
    }
  }, [
    selectedAgency,
    programsRowPerPage,
    tableOption.offset,
    tableOption.searchValue,
    tableOption.sort.direction,
    tableOption.sort.field,
    selectedStatusFilter,
  ]);

  useEffect(() => {
    hasCongCosAccess(congID);
  }, [congID]);

  useEffect(() => {
    if (hasAccess) {
      fetchData();
      fetchCommitteeTable();
      formatRegions();
      fetchAllDistrcit();
      fetchAllAffiliation();

      if (isRoleCongressman) fetchLogs();
    }
  }, [hasAccess]);

  useEffect(() => {
    // fetch budget allocation under specific agency
    if (selectedDropdown) {
      fetchBudgetAllocation();
    }
  }, [selectedDropdown]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selectedRegion !== null && districts.length !== 0) {
      const options = districts.filter(
        (district) => district?.RegionId === selectedRegion.id
      );

      const formattedDistrictOptions = getFormattedOptions("name", options);

      setDistrictOptions(formattedDistrictOptions);

      if (selectedRegion.id !== congDetails.District.RegionId) {
        return setSelectedDistrict(null);
      }

      const selectedOption = formattedDistrictOptions.find(
        (district) => district.id === congDetails.DistrictId
      );
      setSelectedDistrict(selectedOption);
    }
  }, [selectedRegion, districts]);

  useEffect(() => {
    if (
      regionOptions.length !== 0 &&
      Object.keys(congDetails).length !== 0 &&
      selectedRegion === null &&
      parties.length !== 0
    ) {
      const selectedOption = regionOptions.find(
        (region) => region.id === congDetails.District.Region.id
      );

      setSelectedRegion(selectedOption);

      const partylist = parties.find(
        (party) => party.id === congDetails.PartyId
      );
      setSelectedParty(partylist);
    }
  }, [regionOptions, congDetails, parties]);

  useEffect(() => {
    fetchData();
    fetchProjects();

    if (selectedDropdown) fetchBudgetAllocation();
  }, [dateFrom]);

  return (
    <div className="m-0 p-0 cong-container">
      <Row className="cong-header d-flex justify-content-between m-0 px-5 align-items-end">
        <Col
          xs="6"
          className="header-text"
        >
          <h2 className="text-danger">{"Projects & Programs"}</h2>
        </Col>
        <Col
          xs="6"
          className="d-flex justify-content-end align-items-center custom-breadcrumb--comtainer"
        >
          {!isRoleCongressman ? (
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "House Members",
                  redirect: "/representatives",
                },
                {
                  name: congDetails?.District?.Region?.acronym,
                  redirect: `/representatives/${id}`,
                },
                {
                  name: congDetails?.fullName,
                  isActive: true,
                },
              ]}
            />
          ) : null}
        </Col>
      </Row>
      <Row className="m-0 px-5 mt-4 mb-4 d-flex align-items-center cong-details">
        <Col
          xs="auto"
          className="d-flex flex-col flex-direction-col align-items-center"
        >
          <ImgContainer
            src={congDetails?.photo}
            height="230px"
            className="cong-img"
          />
          {isEditMode && (
            <label
              htmlFor="file-upload"
              className="upload-btn"
            >
              {"Upload Photo"}
              <input
                id="file-upload"
                className="file-upload"
                type="file"
                onChange={handleFileUpload}
              />
            </label>
          )}
        </Col>
        <Col className="d-flex flex-column">
          <Row>
            <Col
              style={{
                textAlign: "end",
              }}
            >
              <Link
                to={`/representative/${id}/cong/${congID}/event`}
                onClick={(e) => {
                  e.preventDefault();

                  navigate(`/representatives/${id}/cong/${congID}/event`, {
                    state: { repDetails: congDetails, congLogs: logs },
                  });
                }}
              >
                {"Go to My Events"}
              </Link>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col sm="auto">
              {isEditMode ? null : (
                <h5 className="text-weight-bold mb-3">
                  {congDetails?.fullName}
                </h5>
              )}
            </Col>
            <Col className="d-flex justify-content-end">
              <a
                style={{
                  pointerEvents: tableOption.isLoading ? "none" : "auto",
                }}
                href="!#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditMode(!isEditMode);
                }}
              >
                {!isEditMode ? (
                  <img
                    src={editIcon}
                    alt="edit-icon"
                  />
                ) : (
                  <>
                    <button
                      className="mr-25 cancel-btn btn"
                      type="button"
                    >
                      <p>{"Cancel"}</p>
                    </button>
                    <button
                      className="save-btn btn"
                      type="button"
                      onClick={handleSaveButtonClicked}
                    >
                      {"Save"}
                    </button>
                  </>
                )}
              </a>
            </Col>
          </Row>

          <Row>
            <Col
              xs="6"
              className="details-section"
            >
              {isEditMode && (
                <Row className="mb-2">
                  <Col xs="4">
                    <span className="text-muted">{"First Name"}</span>
                  </Col>
                  <Col
                    xs="8"
                    className="d-flex align-items-center"
                  >
                    <InputGroup
                      type="text"
                      value={tempCongDetails?.firstName}
                      handleOnChange={(e) => {
                        const { value } = e.target;

                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          firstName: value,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          firstName: "",
                        }));
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Committee"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="text"
                      value={tempCongDetails?.committeeChairmanship}
                      handleOnChange={(e) => {
                        const { value } = e.target;

                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          committeeChairmanship: value,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          committeeChairmanship: "",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("committeeChairmanship") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="committeeChairmanship"
                          />

                          <Tooltip
                            id="committeeChairmanship"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {
                                logs[getIndex("committeeChairmanship")]
                                  ?.createdBy
                              }
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("committeeChairmanship")]
                                  ?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">
                        {congDetails?.committeeChairmanship}
                      </span>
                    </>
                  )}
                </Col>
              </Row>
              {congDetails?.District?.Region?.name !== "Partylist" ? (
                // eslint-disable-next-line react/jsx-indent
                <Row className="mb-2">
                  <Col xs="4">
                    <span className="text-muted">{"Region"}</span>
                  </Col>
                  <Col
                    xs="8"
                    className="d-flex align-items-center"
                  >
                    {isEditMode ? (
                      <InputGroup
                        type="select"
                        value={selectedRegion}
                        options={regionOptions}
                        placeholder=""
                        handleOnChange={(option) => {
                          setSelectedRegion(option);
                        }}
                        handleClearInput={() => {
                          setSelectedRegion(null);
                        }}
                      />
                    ) : (
                      <>
                        {getIndex("RegionId") !== -1 ? (
                          <>
                            <Info
                              size={18}
                              data-tooltip-id="RegionId"
                            />

                            <Tooltip
                              id="RegionId"
                              style={{
                                backgroundColor: "black",
                              }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                {"Updated By:"}
                              </div>

                              <span className="d-flex justify-content-center">
                                {logs[getIndex("RegionId")]?.createdBy}
                              </span>

                              <span className="d-flex justify-content-center">
                                {`at ${getFormattedDateString(
                                  logs[getIndex("RegionId")]?.createdAt,
                                  "MMM dd, yyyy"
                                )}`}
                              </span>
                            </Tooltip>
                          </>
                        ) : null}
                        <span className="ml-25">
                          {congDetails?.District?.Region !== null
                            ? congDetails?.District?.Region?.name
                            : ""}
                        </span>
                      </>
                    )}
                  </Col>
                </Row>
              ) : null}
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">
                    {congDetails?.District?.Region?.name !== "Partylist"
                      ? "District"
                      : "Partylist"}
                  </span>
                </Col>
                <Col
                  xs="8"
                  className="align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="select"
                      value={selectedDistrict}
                      options={districtOptions}
                      placeholder=""
                      handleOnChange={(option) => {
                        setSelectedDistrict(option);
                      }}
                      handleClearInput={() => {
                        setSelectedDistrict(null);
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("DistrictId") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="DistrictId"
                          />

                          <Tooltip
                            id="DistrictId"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("DistrictId")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("DistrictId")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">
                        {congDetails?.District?.name}
                      </span>
                    </>
                  )}
                </Col>
              </Row>
              {congDetails?.District?.Region?.name !== "Partylist" ? (
                <Row className="mb-2">
                  <Col xs="4">
                    <span className="text-muted">{"Party"}</span>
                  </Col>
                  <Col
                    xs="8"
                    className="d-flex align-items-center"
                  >
                    {isEditMode ? (
                      <InputGroup
                        type="select"
                        value={selectedParty}
                        options={parties}
                        handleOnChange={(option) => {
                          setSelectedParty(option);
                        }}
                        handleClearInput={() => {
                          setSelectedParty(null);
                        }}
                      />
                    ) : (
                      <>
                        {getIndex("PartyId") !== -1 ? (
                          <>
                            <Info
                              size={18}
                              data-tooltip-id="PartyId"
                            />

                            <Tooltip
                              id="PartyId"
                              style={{
                                backgroundColor: "black",
                              }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                {"Updated By:"}
                              </div>

                              <span className="d-flex justify-content-center">
                                {logs[getIndex("PartyId")]?.createdBy}
                              </span>

                              <span className="d-flex justify-content-center">
                                {`at ${getFormattedDateString(
                                  logs[getIndex("PartyId")]?.createdAt,
                                  "MMM dd, yyyy"
                                )}`}
                              </span>
                            </Tooltip>
                          </>
                        ) : null}
                        <span className="ml-25">
                          {congDetails?.Party?.acronym}
                        </span>
                      </>
                    )}
                  </Col>
                </Row>
              ) : null}
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Term of Office"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="text"
                      value={tempCongDetails?.termOfOffice}
                      handleOnChange={(e) => {
                        const { value } = e.target;

                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          termOfOffice: value,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          termOfOffice: "",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("termOfOffice") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="termOfOffice"
                          />

                          <Tooltip
                            id="termOfOffice"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("termOfOffice")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("termOfOffice")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}

                      <span className="ml-25">{congDetails?.termOfOffice}</span>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              xs="6"
              className="details-section"
            >
              {isEditMode && (
                <>
                  <Row className="mb-2">
                    <Col xs="4">
                      <span className="text-muted">{"Middle Initial"}</span>
                    </Col>
                    <Col
                      xs="8"
                      className="d-flex align-items-center"
                    >
                      <InputGroup
                        type="text"
                        value={tempCongDetails?.middleInitial}
                        handleOnChange={(e) => {
                          const { value } = e.target;

                          setTempcongDetails((prevState) => ({
                            ...prevState,
                            middleInitial: value,
                          }));
                        }}
                        handleClearInput={() => {
                          setTempcongDetails((prevState) => ({
                            ...prevState,
                            middleInitial: "",
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="4">
                      <span className="text-muted">{"Last Name"}</span>
                    </Col>
                    <Col
                      xs="8"
                      className="d-flex align-items-center"
                    >
                      <InputGroup
                        type="text"
                        value={tempCongDetails?.lastName}
                        handleOnChange={(e) => {
                          const { value } = e.target;

                          setTempcongDetails((prevState) => ({
                            ...prevState,
                            lastName: value,
                          }));
                        }}
                        handleClearInput={() => {
                          setTempcongDetails((prevState) => ({
                            ...prevState,
                            lastName: "",
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Birthdate"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="date"
                      value={
                        moment(tempCongDetails?.birthdate).format(
                          "MMM DD, YYYY"
                        ) || null
                      }
                      handleOnChange={(dateString, dateStr) => {
                        const newDate = moment(dateStr).format("YYYY-MM-DD");

                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          birthdate: newDate,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          birthdate: "",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("birthdate") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="birthdate"
                          />

                          <Tooltip
                            id="birthdate"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("birthdate")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("birthdate")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">
                        {tempCongDetails?.birthdate
                          ? moment(tempCongDetails?.birthdate).format(
                              "MMM DD, YYYY"
                            ) || null
                          : null}
                      </span>
                    </>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Contact Number"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="mobile"
                      value={tempCongDetails?.contact || 63}
                      maxLength={12}
                      handleOnChange={(value) => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          contact: value,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          contact: "",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("contact") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="contact"
                          />

                          <Tooltip
                            id="contact"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("contact")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("contact")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">{congDetails?.contact}</span>
                    </>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Chief of Staff"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="text"
                      value={tempCongDetails?.cosName}
                      handleOnChange={(e) => {
                        const { value } = e.target;

                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          cosName: value,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          cosName: "",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("cosName") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="cosName"
                          />

                          <Tooltip
                            id="cosName"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("cosName")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("cosName")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">{congDetails?.cosName}</span>
                    </>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="4">
                  <span className="text-muted">{"Contact Number"}</span>
                </Col>
                <Col
                  xs="8"
                  className="d-flex align-items-center"
                >
                  {isEditMode ? (
                    <InputGroup
                      type="text"
                      value={tempCongDetails?.cosContact || 63}
                      maxLength={12}
                      handleOnChange={(e) => {
                        const { value } = e.target;

                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          cosContact: value,
                        }));
                      }}
                      handleClearInput={() => {
                        setTempcongDetails((prevState) => ({
                          ...prevState,
                          cosContact: "",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {getIndex("cosContact") !== -1 ? (
                        <>
                          <Info
                            size={18}
                            data-tooltip-id="cosContact"
                          />

                          <Tooltip
                            id="cosContact"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              {"Updated By:"}
                            </div>

                            <span className="d-flex justify-content-center">
                              {logs[getIndex("cosContact")]?.createdBy}
                            </span>

                            <span className="d-flex justify-content-center">
                              {`at ${getFormattedDateString(
                                logs[getIndex("cosContact")]?.createdAt,
                                "MMM dd, yyyy"
                              )}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : null}
                      <span className="ml-25">{congDetails?.cosContact}</span>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {hasPermission("committee-view") && (
        <Row className="committee-row d-flex mx-5">
          <Col xs="auto" />
          <Col>
            <h4>{"Committee Membership"}</h4>
            <Table
              responsive
              className="table-responsive table-bordered committee-container"
            >
              <thead className="header">
                <tr className="">
                  <th className="">
                    <Col>{"Position"}</Col>
                  </th>
                  <th className="">
                    <Col>{"Committee"}</Col>
                  </th>
                  <th className="">
                    <Row className="m-0 text-center flex-nowrap">
                      <Col className="d-flex justify-content-end">{"Bloc"}</Col>
                      <Col className="d-flex justify-content-start">
                        {committeeDetails?.block}
                      </Col>
                    </Row>
                  </th>
                  <th className="">
                    <Row className="m-0 text-center flex-nowrap">
                      <Col className="d-flex justify-content-end">
                        {"Total"}
                      </Col>

                      <Col>{committeeDetails?.memberships?.count || 0}</Col>
                      <Col
                        className="d-flex justify-content-start"
                        href="#"
                        onClick={() => setIsCommitteShown(!isCommitteShown)}
                      >
                        {!isCommitteShown ? (
                          <img
                            alt="dropdownIcon"
                            src={dropdownIcon}
                            height="24px"
                            id="dropdown-icon"
                          />
                        ) : (
                          <img
                            alt="dropdownIconRotated"
                            src={dropdownIconRotated}
                            height="24px"
                            id="dropdown-icon"
                          />
                        )}
                      </Col>
                    </Row>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isCommitteShown ? (
                  <>
                    <tr className="title">
                      <td
                        colSpan={2}
                        className="font-weight-bold"
                      >
                        <h5>{"Position"}</h5>
                      </td>
                      <td
                        colSpan={2}
                        className="font-weight-bold"
                      >
                        <h5>{"Committee"}</h5>
                      </td>
                    </tr>

                    {Array.isArray(committeeDetails?.memberships?.rows) &&
                    committeeDetails?.memberships?.rows?.length !== 0
                      ? committeeDetails?.memberships?.rows?.map((committe) => {
                          return (
                            <tr className="list">
                              <td
                                className="text-uppercase"
                                colSpan={2}
                              >
                                <p>{committe?.position}</p>
                              </td>
                              <td
                                className="text-uppercase"
                                colSpan={2}
                              >
                                <p>{committe?.committee}</p>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </>
                ) : null}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      <Row className="committee-row mx-5">
        <Col xs="auto" />
        <Col>
          <div className="budget-container cong">
            <div className="d-flex justify-content-between align-items-center p-0">
              <Col className="pl-0">
                <h5 className="font-weight-bold">{"Budget Allocation"}</h5>
              </Col>
            </div>
            <div className="p-0 mt-4 mb-4">
              <StackedProgressBar data={projects} />
            </div>
            <div className="p-0">
              <div className="pl-0 d-flex flex-row">
                <h6>{"Total Amount Allocated:\r"}</h6>
                <h6 className="font-weight-bold ml-25">
                  {getFormattedNumber(totalBudget || "0.00")}
                </h6>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="table">
        <Row className="pt-4 mb-4 mx-5 d-flex align-items-center select-container">
          <Col>
            <Row className="d-flex align-items-center agency-selector">
              <Col
                xs="auto"
                className="p-0 project-logo d-flex justify-content-center"
              >
                {/* agency logo here */}
                {selectedAgency?.photo && (
                  <ImgContainer
                    src={selectedAgency?.photo}
                    height="110px"
                  />
                )}
              </Col>

              <Col
                xs="12"
                sm="8"
                md="5"
                lg="5"
                xl="6"
              >
                {/* agency select dropwdown here */}
                <CustomSelect
                  className="p-0"
                  isLoading={tableOption.isLoading}
                  isDisabled={tableOption.isLoading}
                  onChange={(option) => handleSelectOnChange(option)}
                  value={selectedAgency}
                  options={selectorOptions}
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                  // eslint-disable-next-line consistent-return
                  filterOption={(option, searchValue) => {
                    if (
                      option.data.acronym
                        .toLowerCase()
                        .includes(searchValue?.toLowerCase())
                    ) {
                      return option;
                    }
                  }}
                  // menuIsOpen
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          className="d-flex mx-5 table-section"
          style={{ flexWrap: "nowrap" }}
        >
          <Col xs="auto" />
          <Col
            xs="12"
            lg="10"
            sm="9"
            xl="11"
          >
            <Row
              className="p-0 d-flex search-status-row"
              style={{ flexWrap: "nowrap" }}
            >
              <Col
                lg="2"
                className="d-flex align-items-center justify-content-start d-none d-lg-flex"
              >
                <div className="position-relative search-section">
                  <SearchBarComponent
                    onChangeSearch={onChangeSearch}
                    isDisabled={tableOption?.isLoading}
                  />
                </div>
              </Col>

              {canEditRoles.includes(role) && (
                <Col
                  lg="4"
                  className="d-flex align-items-center justify-content-start d-none d-lg-flex status-filter"
                >
                  <CustomSelect
                    placeholder="Status"
                    name="statusFilter"
                    styles={statusFilterStyle}
                    classNamePrefix="select"
                    onChange={handleChangeFilter}
                    value={selectedStatusFilter}
                    components={{ DropdownIndicator }}
                    options={statusFilter}
                    isMulti
                    isSearchable={false}
                    hideSelectedOptions={false}
                  />
                </Col>
              )}
            </Row>

            <Card className="mb-5">
              <CustomDataTable
                columns={columns}
                data={tableOption.data}
                noHeader
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onSort={onSort}
                paginationPerPage={programsRowPerPage}
                paginationServer
                paginationTotalRows={tableOption.totalRows}
                progressPending={tableOption.isLoading}
                sortServer
                defaultSortField={tableOption.sort.field}
                defaultSortAsc={tableOption.sort.direction}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <CommentsModal
        isOpen={modalVisible}
        handleClose={toggleModal}
        fetchComments={fetchRecipientMsgs}
        comments={recipientComments}
        congID={editingCongressman}
        congressmanId={congID}
        selectedAgency={selectedAgency}
        handleFetchTable={fetchProjects}
      />
    </div>
  );
};

export default Main;
