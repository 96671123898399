import { toast } from "react-toastify";
import awsconfig from "config/aws-config";
import {
  getUserDetails,
  getRefreshToken,
  setRefreshToken,
  setAccessToken,
  getAccessToken,
  getIdToken,
  setIdToken,
  setUserDetails,
  getUserPermissions,
  setUserPermissions,
} from "config";
import { format } from "date-fns";
import { Auth } from "aws-amplify";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToWords } from "to-words";
import { isNumber } from "lodash";

const AWS = require("aws-sdk");

const {
  CognitoUserPool,
  CognitoUser,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} = require("amazon-cognito-identity-js");

/**
 * Returns an array split into n parts
 *
 * @param {array} array
 * @param {parts} number
 */
const splitArrayToChunks = (array, parts) => {
  const result = [];

  // eslint-disable-next-line no-plusplus
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }

  return result;
};

const Toast = (config = {}) => {
  const type = [
    toast.TYPE.DEFAULT,
    toast.TYPE.SUCCESS,
    toast.TYPE.INFO,
    toast.TYPE.WARNING,
    toast.TYPE.ERROR,
  ][config.type || 0];

  toast.error(config.content, {
    position: config.position || "top-right",
    autoClose: config.autoClose !== undefined ? config.autoClose : 3000,
    hideProgressBar: config.hideProgressBar || false,
    closeOnClick: config.closeOnClick || true,
    pauseOnHover: config.pauseOnHover || true,
    draggable: config.draggable || true,
    progress: config.progress || undefined,
    type,
    theme: "light",
  });
};

const getTokens = (session) => {
  return {
    accessToken: session.getAccessToken().getJwtToken(),
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

const changeUserPassword = async (username, newPassword) => {
  const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(
    {
      accessKeyId: awsconfig.aws_app_access_id,
      secretAccessKey: awsconfig.aws_app_access_key,
      region: awsconfig.aws_cognito_region,
    }
  );

  try {
    const params = {
      UserPoolId: awsconfig.aws_user_pools_id,
      Username: username,
      Password: newPassword,
      Permanent: true,
    };

    await cognitoIdentityServiceProvider.adminSetUserPassword(params).promise();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Errored", error);
  }
};

const refreshTokenExpired = () => {
  try {
    const userPool = new CognitoUserPool({
      ClientId: awsconfig.aws_user_pools_web_client_id,
      UserPoolId: awsconfig.aws_user_pools_id,
    });

    const userDetails = getUserDetails();

    const username = userDetails?.username;

    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    const refreshToken = getRefreshToken();

    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });

    user.refreshSession(token, (err, session) => {
      if (err) throw err;

      if (session) {
        setAccessToken(session?.accessToken?.jwtToken);
        setRefreshToken(session?.refreshToken?.token);
        setIdToken(session?.idToken?.jwtToken);
      }
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

const checkTokenExpiration = () => {
  const AccessToken = new CognitoAccessToken({ AccessToken: getAccessToken() });
  const IdToken = new CognitoIdToken({ IdToken: getIdToken() });

  const RefreshToken = new CognitoRefreshToken({
    RefreshToken: getRefreshToken(),
  });

  const sessionData = {
    IdToken,
    AccessToken,
    RefreshToken,
  };

  const cachedSession = new CognitoUserSession(sessionData);

  return cachedSession.isValid();
};

const getLocalSession = async () => {
  try {
    const session = await Auth.currentSession();

    if (session) {
      setAccessToken(session?.accessToken?.jwtToken);
      setRefreshToken(session?.refreshToken?.token);
      setIdToken(session?.idToken?.jwtToken);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);

    if (
      e === "No current user" ||
      Object.values(e)[0] === "NotAuthorizedException"
    ) {
      localStorage.clear();

      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    }
  }
};

const isTokenExpired = () => {
  const token = getAccessToken();

  const now = Date.now().valueOf() / 1000;
  const expiry = JSON.parse(window.atob(token.split(".")[1])).exp;

  return now > expiry;
};

const removeAuthentication = () => {
  setAccessToken(null, true);
  setIdToken(null, true);
  setRefreshToken(null, true);
  setUserDetails(null, true);
  setUserPermissions(null, true);
};

/**
 * Returns an array of options formatted in label, value
 *
 * @param {key} string
 * @param {rawdata} array
 */
const getFormattedOptions = (key, rawdata) => {
  const newData = rawdata?.map((data) => ({
    ...data,
    label: data[key],
    value: data?.id,
  }));

  return newData;
};

/**
 * Returns an array of options formatted in label, value
 *
 * @param {data} array
 */
const getRegionOptions = (data) => {
  const newData =
    data?.map((region) => ({
      ...region,
      value: region.id,
      label: `${region?.acronym} - ${region?.name}`,
    })) || [];

  return newData;
};

/**
 * Returns a date string based on the format given
 *
 * @param {dateString} string
 * @param {format} string
 */
const getFormattedDateString = (dateString, formatString) => {
  if (!dateString || dateString === null) {
    return "";
  }

  return format(new Date(dateString), formatString);
};

/**
 * Returns a boolean if logged in user has
 * permission to proceed requested action
 *
 * @param {action} string
 */
const hasPermission = (action) => {
  return getUserPermissions()?.includes(action);
};

/**
 * Returns a formatted number with thousand separator
 * and PHP sign
 * @param {number} string
 */
const getFormattedNumber = (number) => {
  return `PHP ${number.toLocaleString("en-US")}`;
};

/**
 * Returns true if parameter is null || empty string || white spaces
 * @param {variable} string
 */
const isVariableEmpty = (variable) => {
  return variable === "" || variable === null || /^\s*$/.test(variable);
};

/**
 * Returns a contact number in the format of 639XXXXXXXXX
 * @param {contact} string
 */
const getFormattedContactNumber = (contact) => {
  let contactNumber = contact;

  if (contact && contact.includes("+63")) {
    // eslint-disable-next-line prefer-destructuring
    contactNumber = contact.split("+")[1];
  }

  if (contact && contact.includes("-")) {
    contactNumber = contact.split("-").join("");

    if (contactNumber.length === 11) {
      contactNumber = `639${contactNumber.split("09")[1]}`;
    }
  }

  if (contact && contact.includes(" ")) {
    contactNumber = contact.split(" ").join("");

    if (contactNumber.length === 11) {
      contactNumber = `639${contactNumber.split("09")[1]}`;
    }

    if (contactNumber.length === 10) {
      contactNumber = `63${contactNumber}`;
    }
  }

  if (
    contact &&
    /^09/.test(contact) &&
    !contact.includes("-") &&
    !contact.includes(" ")
  ) {
    contactNumber = `639${contact.slice(2)}`;
  }

  if (contact && contact.length === 10) {
    contactNumber = `63${contact}`;
  }

  return contactNumber;
};

/**
 * Returns a newData object that lists the edited fields
 * based on the two params
 * @param {initialData} object
 * @param {data} object
 */
const getModifiedObject = (initialData, data) => {
  const newData = {};

  // eslint-disable-next-line array-callback-return
  Object.keys(initialData).map((obj) => {
    if (data[data] === "recipient") {
      if (data[data] && initialData[data].fullName !== data[obj].fullName) {
        newData[obj] = data[obj];
      }
    } else if (data[obj] && initialData[obj] !== data[obj]) {
      newData[obj] = data[obj];
    }
  });

  return newData;
};

const formatTime = (timeString) => {
  if (!timeString) return "";
  const date = new Date(`1970-01-01T${timeString}`);

  return date
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .toUpperCase();
};

const formatTimeToServer = (date) => {
  if (!date) return null;

  return date.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

// eslint-disable-next-line consistent-return
const convertNumberToPesoWords = (amount) => {
  try {
    if (!isNumber(amount)) throw Error("Amount must be a integer/float");

    const toWords = new ToWords({
      localeCode: "en-PH",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
      },
    });

    return toWords.convert(amount);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export {
  changeUserPassword,
  splitArrayToChunks,
  Toast,
  refreshTokenExpired,
  checkTokenExpiration,
  getLocalSession,
  isTokenExpired,
  removeAuthentication,
  getFormattedOptions,
  getFormattedDateString,
  hasPermission,
  getFormattedNumber,
  isVariableEmpty,
  getRegionOptions,
  getFormattedContactNumber,
  getModifiedObject,
  getTokens,
  formatTime,
  formatTimeToServer,
  convertNumberToPesoWords,
};
