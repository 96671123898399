import styled from "styled-components";
import { Button } from "reactstrap";

const CommentsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;

  &:has(.unread-msgs) {
    bottom: 10px;
  }
`;

const ViewCommentBtn = styled(Button)`
  color: white;
  background: #5a69da;
  border: 1px solid #5a69da;
  border-radius: 27px;
`;

const AddCommentBtn = styled(Button)`
  background: #f8f8f8;
  border: 1px solid #5a69da;
  border-radius: 27px;
  color: #5a69da;
`;

const TableSection = styled.div`
  margin-top: 4rem;

  .rdt_Table {
    .select__control:has(.invited-status) {
      background-color: #112bab;
      border: none;
      .invited-status {
        color: white;
      }
    }

    .select__control:has(.confirmed-status) {
      background-color: #53a24c;
      border: none;
      .confirmed-status {
        color: white;
      }
    }

    .select__control:has(.declined-status) {
      background-color: #df3348;
      border: none;
      .declined-status {
        color: white;
      }
    }

    .select__control:has(.no-response-status) {
      background-color: #ffbd14;
      border: none;
    }

    .select__control:has(.attended-status) {
      background-color: #5a69da;
      border: none;
      .attended-status {
        color: white;
      }
    }

    .select__control:has(.did-not-attend-status) {
      background-color: #e1700d;
      border: none;
      .did-not-attend-status {
        color: white;
      }
    }
  }
`;

export { CommentsContainer, ViewCommentBtn, AddCommentBtn, TableSection };
