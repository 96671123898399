import React, { useEffect, useState } from "react";
import { Row, Col, Card, UncontrolledTooltip, Button, Badge } from "reactstrap";
import { Tooltip } from "react-tooltip";
import BreadCrumbComponent from "components/customs/breadCrumb";
import SearchBarComponent from "components/customs/searchbarV2";
import CustomDataTable from "components/customs/table";
import CustomSelect from "components/customs/dropdown";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormGenertor from "components/customs/form-generator/input";
import { Edit2, Eye, RotateCcw, Trash2 } from "react-feather";
import { APP_URL, getUserDetails } from "config";
import ImgContainer from "components/common/img";
import {
  getFormattedDateString,
  getFormattedNumber,
  getFormattedOptions,
  getModifiedObject,
  hasPermission,
  Toast,
} from "utility/helpers";
import CommentsModal from "components/customs/comments-modal";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import StackedProgressBar from "components/customs/stacked-progress-bar";
import BudgetComponent from "components/customs/budget";
import {
  deleteRecipient,
  editTotalBudget,
  fetchAgencyDetails,
  fetchProgramComments,
  fetchTable,
  recipientBatchUpdate,
  deleteRecipients,
} from "services/services.projects";
import Swal from "sweetalert2";
import { fetchAllRegions } from "services/services.congressman";
import { genericGet, genericPost } from "services/services.general";
import {
  statuses,
  defaultTableOption,
  DropdownIndicator,
  SelectDropdownIndicator,
  regionSelectStyles,
  statusSelectStyles,
  searchInputStyles,
  batchStatusStyle,
  statusFilterStyle,
  customProjectStyles,
  canEditRoles,
  congRoles,
} from "utility/constants";
import CustomCheckbox from "components/customs/checkbox";
import ResendModal from "components/customs/resendModal";
import api from "services/api";
import useIsEllipsisActive from "hooks/useIsEllipsisActive";
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePersistentCountdownTimer from "hooks/usePersistentTimer";
import DohEndorsement from "components/customs/pdf/doh";
import ChedEndorsement from "components/customs/pdf/ched";
import DoleEndorsement from "components/customs/pdf/dole";
import DswdEndorsement from "components/customs/pdf/dswd";
import TesdaEndorsement from "components/customs/pdf/tesda";
import { fetchPendingProjects } from "../../../../redux/actions/projects";

import "../../styles.scss";

const RESEND_STATUSES = ["Approved", "Pending"];

const resendOptions = [
  {
    id: 1,
    label: "General",
    value: "general",
  },
  {
    id: 2,
    label: "Approval",
    value: "approval",
  },
];

const statusFilter = [
  { value: "Requested", label: "Requested" },
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Endorsed" },
  { value: "Processed", label: "Processed" },
  { value: "Downloaded", label: "Downloaded" },
  { value: "Disapproved", label: "Pls See Comment" },
];

const agencyWithSpecialForms = [3, 8, 27, 14];

const programsRowPerPage = 100;

const resendIconStatus = ["Approved", "Downloaded", "Processed"];

const allowedBumpStatus = ["Approved", "Pending", "Requested"];

const Main = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [selectedProject, setSelectedProject] = useState(null);
  const [editingRowId, setEditingRowId] = useState();
  const [initialProjects, setInitialProjects] = useState([]);
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [isModalShown, setIsModalShown] = useState(false);
  const [editingCongressman, setEditingCongressman] = useState("");
  const [comments, setComments] = useState([]);
  const [regions, setRegions] = useState([]);
  const [projectBudget, setProjectBudget] = useState([]);
  const [isBudgetEditing, setIsBudgetEditing] = useState(false);
  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [totalBudget, setTotalBudget] = useState("");
  const [remainingBalance, setRemainingBalance] = useState("");
  const [newTotalBudget, setNewTotalBudget] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isResendModalShown, setIsResendModalShown] = useState(false);
  const [resendData, setResendData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [resendType, setResendType] = useState("");
  const [messageType, setMessageType] = useState("");

  const [selectedStatusFilter, setSelectedStatusFilter] = useState();

  const navigate = useNavigate();

  const { id, programID } = useParams();

  const role = getUserDetails()?.type;

  let changeSearchTimeoutID;

  const dateRange = useSelector((state) => state.dateRange);
  const { dateFrom, dateTo } = dateRange || {};

  const dispatch = useDispatch();

  const { isEllipsisUsed } = useIsEllipsisActive();

  const { timer, toggleTimer } = usePersistentCountdownTimer();

  const toggleModal = () => setIsModalShown(!isModalShown);
  const toggleResendModal = () => setIsResendModalShown(!isResendModalShown);
  const toggle = () => setIsBudgetEditing(!isBudgetEditing);

  const isExecutive = canEditRoles.includes(role);

  const handleSearchRecepientOnChange = (option, index) => {
    const newData = [...tableOption.data];

    newData[index] = {
      ...newData[index],
      recipient: {
        firstName: option?.firstName,
        lastName: option?.lastName,
        fullName: `${option?.lastName}, ${option?.firstName}`,
      },
    };

    setTableOption({
      ...tableOption,
      data: newData,
    });
  };

  const handleCancelClicked = (index) => {
    setEditingRowId("");
    setEditingCongressman("");

    const newData = [...tableOption.data];
    newData[index] = initialProjects[index];

    setTableOption({
      ...tableOption,
      data: newData,
    });
  };

  const fetchComments = (congid) => {
    fetchProgramComments({
      params: congid,
      callback: (data) => {
        setComments(data);
      },
    });
  };

  const getStatusOptionsPosition = () => {
    if (initialProjects?.length === 1) {
      return "bottom";
    }

    if (
      editingRowId === initialProjects.length - 1 &&
      initialProjects.length > 3
    ) {
      return "top";
    }

    return "bottom";
  };

  const canEditRow = (status) => {
    let canEdit = false;

    if (
      role === "Editor" &&
      (status === "Pending" ||
        status === "Requested" ||
        status === "Disapproved")
    ) {
      canEdit = true;
    } else if (role === "Director" && status !== "Not Eligible") {
      canEdit = true;
    } else if (
      role === "Agency" &&
      status !== "Disapproved" &&
      status !== "Pending" &&
      status !== "Not Eligible"
    ) {
      canEdit = true;
    } else if (
      congRoles.includes(role) &&
      (status === "Requested" || status === "For Resubmission")
    ) {
      canEdit = true;
    }

    return canEdit;
  };

  const cannotEditStatus = (status) => {
    let cannotEdit = true;

    if (
      role === "Director" &&
      status !== "Processed" &&
      status !== "In-Progress" &&
      status !== "Downloaded"
    ) {
      cannotEdit = false;
    } else if (
      role === "Agency" &&
      status !== "Disapproved" &&
      status !== "Pending"
    ) {
      cannotEdit = false;
    } else if (
      role === "Editor" &&
      (status === "Requested" ||
        status === "Pending" ||
        status === "Disapproved")
    ) {
      cannotEdit = false;
    }

    return cannotEdit;
  };

  const getStatusOptions = (status) => {
    let options = statuses;

    if (role === "Director") {
      if (status !== "Approved") {
        options = options?.filter(
          (option) =>
            option.value !== "Processed" &&
            option.value !== "In-Progress" &&
            option.value !== "Downloaded" &&
            option.value !== "Requested" &&
            option.value !== "For Resubmission" &&
            option.value !== "Not Eligible"
        );
      } else {
        options = options?.filter(
          (option) =>
            option.value !== "Processed" &&
            option.value !== "In-Progress" &&
            option.value !== "Downloaded" &&
            option.value !== "Requested"
        );
      }
    }

    if (role === "Agency") {
      if (status === "Approved") {
        options = options?.filter(
          (option) =>
            option.value === "Processed" ||
            option.value === "Downloaded" ||
            option.value === "For Resubmission" ||
            option.value === "Not Eligible"
        );
      } else {
        options = options?.filter(
          (option) =>
            option.value === "Processed" ||
            option.value === "Downloaded" ||
            option.value === "Approved"
        );
      }
    }

    if (role === "Editor") {
      options = options?.filter(
        (option) =>
          option.value === "Requested" ||
          option.value === "Pending" ||
          option.value === "Disapproved"
      );
    }

    return options;
  };

  const onChangePage = (page) => {
    // reset editing row
    setEditingRowId();

    setTableOption({
      ...tableOption,
      page,
      offset: programsRowPerPage * page - 100,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    // reset editing row
    setEditingRowId();

    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  const onSort = (column, sortDirection) => {
    // reset editing row
    setEditingRowId();

    setTableOption({
      ...tableOption,
      sort: {
        field: column.identifier,
        direction: sortDirection,
      },
    });
  };

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;
      // reset editing row
      setEditingRowId();

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const fetchData = () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    const params = {
      limit: programsRowPerPage,
      offset: tableOption.offset,
      search: tableOption.searchValue,
      column: tableOption.sort.field,
      order: tableOption.sort.direction,
      startDate: dateFrom || "",
      endDate: dateTo || "",
    };

    if (role !== "Agency") {
      params.status = selectedStatusFilter?.map((item) => item.value).join(";");
    }

    fetchTable({
      url: `/project/${programID}/recipients/`,
      params,
      callback: (data) => {
        setTableOption({
          ...tableOption,
          data: data?.rows,
          totalRows: data?.count,
          isLoading: false,
        });
        setInitialProjects(data?.rows);
      },
    });
  };

  const handleChangeFilter = (selected) => {
    setSelectedStatusFilter(selected || []);
  };

  const getSelectorOptions = (rawOptions) => {
    if (rawOptions?.length !== 0) {
      const options =
        rawOptions?.map((select) => ({
          ID: select?.id,
          Name: select?.name,
          label: <h6 className="font-weight-bold mb-0">{select?.name}</h6>,
        })) || [];

      setSelectOptions(options);
    }
  };

  const fetchAgency = () => {
    fetchAgencyDetails({
      params: id,
      callback: (data) => {
        getSelectorOptions(data?.Projects);
        setAgencyDetails(data);
      },
      dateRange,
    });
  };

  const fetchBudgetAllocation = () => {
    genericGet({
      url: `/project/${programID}/allocatedBudget`,
      params: {
        startDate: dateFrom || "",
        endDate: dateTo || "",
      },
      callback: (data) => {
        const budgetRecipient = data?.allocatedBudgetPerRecipient;

        const totalAgencyBudget =
          data?.totalBudget !== null ? data?.totalBudget : 0;

        const amountSpent =
          data?.allocatedBudget !== null ? data?.allocatedBudget : 0;
        const remainingBudget = totalAgencyBudget - amountSpent;
        let projs = [];

        setTotalBudget(totalAgencyBudget);
        setTotalAmountSpent(amountSpent);
        setRemainingBalance(remainingBudget);

        if (budgetRecipient?.length !== 0) {
          projs = budgetRecipient?.map((proj, index) => {
            return {
              id: index + 1,
              name: proj?.region,
              value: proj.budget,
              budget:
                proj.budget !== 0 ? (proj.budget / totalAgencyBudget) * 100 : 0,
            };
          });
        }

        projs.push({
          id: 0,
          name: "Remaining Balance",
          value: remainingBudget,
          budget:
            remainingBudget !== 0
              ? (remainingBudget / totalAgencyBudget) * 100
              : 0,
        });

        setProjectBudget(projs);

        if (totalAgencyBudget === 0) setProjectBudget([]);
      },
    });
  };

  const batchUpdate = ({ formData, callback }) => {
    recipientBatchUpdate({
      url: `${APP_URL}/recipient/batch-update/status`,
      formData,
      callback,
    });
  };

  const handleSaveStatusChange = async (data, index) => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    const initialData = initialProjects[index];
    const newData = getModifiedObject(initialData, data);

    const finalData = {};
    let pdf = "";
    finalData.status = data?.status;

    if (data?.status === "Approved") {
      const details = data;
      details.program = selectedProject?.Name;
      details.agency = agencyDetails?.name;
      details.agencyAcronym = agencyDetails?.acronym;

      switch (id) {
        case "8":
          pdf = await DohEndorsement(details);
          break;

        case "28":
          pdf = await ChedEndorsement(details);
          break;

        case "11":
          pdf = await DoleEndorsement(details);
          break;

        case "16":
          pdf = await DswdEndorsement(details);
          break;

        case "27":
          pdf = await TesdaEndorsement(details);
          break;

        default:
          break;
      }
    }

    finalData.recipients = [
      {
        recipientId: data?.id,
        endorsementLink: pdf,
        ...newData,
      },
    ];

    batchUpdate({
      formData: finalData,
      callback: ({ isSuccess, msg }) => {
        if (isSuccess) {
          fetchData();
          if (role === "Director") dispatch(fetchPendingProjects());
          setSelectedStatus("");
          setResendData([]);

          const text = msg.replace(/\n/g, "<br>");

          Swal.fire({
            title: text.includes("<br>") ? "" : "Success!",
            icon: "success",
            html: text,
            customClass: text.includes("<br>")
              ? { container: "sms-notif" }
              : null,
            confirmButtonText: "OK",
          });
        } else {
          handleCancelClicked(index);

          Toast({
            type: 4,
            content: "An error occured!",
          });
        }
      },
    });
  };

  const handleRemarks = (text, recipientId, url) => {
    genericPost({
      url: "/comment",
      formData: {
        text,
        recipientId,
        newsId: null,
      },
      callback: () => {
        //
      },
    });

    genericPost({
      url,
      formData: {
        remarks: text,
        recipientId,
      },
      callback: () => {
        //
      },
    });
  };

  const handleDeleteAction = (row, idx) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to remove ${row?.recipient?.lastName}, ${row?.recipient?.firstName} from ${selectedProject?.Name}?`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecipient({
          params: row?.id,
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully deleted recipient.",
              });
              fetchData();
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      } else if (idx !== undefined && idx !== null) {
        handleSaveStatusChange(row, idx);
      }
    });
  };

  // eslint-disable-next-line consistent-return
  const handleStatusChange = async (index, option) => {
    const newData = [...tableOption.data];
    const oldValue = newData[index].status;
    const newValue = option.value;

    const oldStatus = statuses.find(
      (status) => status.value === newData[index].status
    ).label;

    if (oldValue.toLowerCase() !== newValue.toLowerCase()) {
      try {
        const result = await Swal.fire({
          title: "Warning",
          // eslint-disable-next-line max-len
          text: `Are you sure you want to change the status from ${oldStatus} to ${option.label}?`,
          icon: "warning",
          confirmButtonText: "OK",
          showCancelButton: true,
        });

        if (result.isConfirmed) {
          newData[index] = {
            ...newData[index],
            status: newValue,
          };

          setTableOption({
            ...tableOption,
            data: newData,
          });

          if (newValue === "For Resubmission" || newValue === "Not Eligible") {
            const { value: text } = await Swal.fire({
              input: "textarea",
              inputLabel: "Remarks",
              inputPlaceholder: "Type your remarks here...",
              inputAttributes: {
                "aria-label": "Type your remarks here",
              },
              showCancelButton: true,
            });

            if (text) {
              newData[index] = {
                ...newData[index],
                remarks: text,
              };

              const url =
                newValue === "For Resubmission"
                  ? "/recipient/send-resubmission-notification"
                  : "/recipient/send-not-eligible-notification";
              handleRemarks(text, newData[index].id, url);
            } else return;
          }

          if (newValue === "Not Eligible" && role === "Director") {
            const res = await Swal.fire({
              title: "Warning",
              text: "Not Eligible allocations are opt to be deleted. Do you wish to proceed?",
              icon: "info",
              confirmButtonText: "OK",
              showCancelButton: true,
            });

            if (res.isConfirmed) {
              handleDeleteAction(newData[index], index);

              return;
            }
          }

          handleSaveStatusChange(newData[index], index);
        } else {
          newData[index] = {
            ...newData[index],
            status: oldValue,
          };

          setTableOption({
            ...tableOption,
            data: newData,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const fetchRegions = () => {
    fetchAllRegions({
      callback: (data) => {
        const options = getFormattedOptions("acronym", data);

        setRegions(options);
      },
    });
  };

  const handleOverallBudgetChange = (budget) => {
    if (totalBudget !== 0 && budget > agencyDetails?.totalBudget) {
      Swal.fire({
        title: "Error",
        // eslint-disable-next-line max-len
        text: `Budget allocation for ${selectedProject.Name} exceeds the total budget allocation under ${agencyDetails?.name}`,
        icon: "error",
        confirmButtonText: "OK",
      });
      setNewTotalBudget(totalBudget);

      return;
    }

    editTotalBudget({
      formData: {
        totalBudget: budget,
      },
      url: `/project/${programID}`,
      callback: (response) => {
        const status = response?.status;

        if (status) {
          Toast({
            type: 1,
            content: "Successfully updated total budget",
          });
          fetchBudgetAllocation();
        } else {
          setNewTotalBudget(totalBudget);
        }
      },
    });
  };

  const handleMultipleDelete = async () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });
    const projectIds = checkedItems.join(",");

    const result = await Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: "Are you sure you want to remove the selected recipients?",
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      deleteRecipients({
        params: projectIds,
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            setCheckedItems([]);
            setSelectedStatus("");

            Toast({
              type: 1,
              content: "Successfully deleted selected recipients.",
            });
            fetchData();
            if (role === "Director") dispatch(fetchPendingProjects());
          } else {
            setCheckedItems([]);

            Toast({
              type: 4,
              content: "An error occured.",
            });
          }
        },
      });
    }

    if (result.isDismissed) {
      setCheckedItems([]);
      setSelectedStatus("");

      setTableOption({
        ...tableOption,
        isLoading: false,
      });
    }
  };

  const handleBatchUpdate = async (option) => {
    const newStatus = option?.value;

    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    const finalData = {};
    finalData.status = newStatus;
    finalData.recipients = checkedItems;

    const oldValue = resendData[0]?.status;

    try {
      const res = await Swal.fire({
        title: "Warning",
        // eslint-disable-next-line max-len
        text: `Are you sure you want to change status from ${oldValue} to ${option.label}?`,
        icon: "warning",
        confirmButtonText: "OK",
        showCancelButton: true,
      });

      if (res.isConfirmed) {
        if (newStatus === "Approved") {
          const endorsements = await Promise.all(
            resendData?.map(async (row) => {
              const details = { ...row };
              details.program = selectedProject?.Name;
              details.agency = agencyDetails?.name;
              details.agencyAcronym = agencyDetails?.acronym;

              let link = "";

              switch (id) {
                case "8":
                  link = await DohEndorsement(details);
                  break;

                case "28":
                  link = await ChedEndorsement(details);
                  break;

                case "11":
                  link = await DoleEndorsement(details);
                  break;

                case "16":
                  link = await DswdEndorsement(details);
                  break;

                case "27":
                  link = await TesdaEndorsement(details);
                  break;
                default:
                  break;
              }

              return {
                id: row?.id,
                endorsementLink: link,
              };
            })
          );

          finalData.endorsements = endorsements;
        }

        await batchUpdate({
          formData: finalData,
          callback: ({ isSuccess, msg }) => {
            if (isSuccess) {
              setCheckedItems([]);
              fetchData();
              if (role === "Director") dispatch(fetchPendingProjects());
              setSelectedStatus("");
              setResendData([]);

              const text = msg.replace(/\n/g, "<br>");

              Swal.fire({
                title: text.includes("<br>") ? "" : "Success!",
                icon: "success",
                html: text,
                customClass: text.includes("<br>")
                  ? { container: "sms-notif" }
                  : null,
              });
            } else {
              setCheckedItems([]);

              Toast({
                type: 4,
                content: "An error occured!",
              });
            }
          },
        });
      }

      if (res.isDismissed) {
        setCheckedItems([]);
        setSelectedStatus("");
        setResendData([]);

        setTableOption({
          ...tableOption,
          isLoading: false,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const editableRows = tableOption.data?.reduce((data, row) => {
    if (canEditRow(row.status)) {
      return [...data, row.id];
    }

    return data;
  }, []).length;

  const haveSameStatus = () => {
    const referenceStatus = tableOption.data[0]?.status;

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < tableOption.data.length; i++) {
      if (tableOption.data[i]?.status !== referenceStatus) {
        return false;
      }
    }

    return true;
  };

  const handleBumpIconOnClicked = async (congId, status) => {
    const result = await Swal.fire({
      title: "Warning",
      text: "Would you like to bump this allocation?",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      setTableOption({
        ...tableOption,
        isLoading: true,
      });

      toggleTimer(congId);

      genericPost({
        url: "/comment",
        formData: {
          text: "bumped this notification.",
          recipientId: congId,
        },
        callback: () => {
          //
        },
      });

      genericPost({
        url: "/recipient/bump-allocation-notification",
        formData: {
          status,
          recipientId: congId,
        },
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            fetchData();
            fetchComments(congId);
          }
        },
      });
    }
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status }) => (
        <div
          className={`${
            status === "Pending" || status === "Requested" ? "is-pending" : ""
          }`}
        />
      ),
    },
    {
      name: isExecutive && haveSameStatus() && (
        <div className="w-100 p-0 d-flex justify-content-center">
          <CustomCheckbox
            checked={editableRows === checkedItems.length}
            onChange={(evt) => {
              const { checked } = evt.target;

              if (checked) {
                const items = tableOption.data.reduce((data, row) => {
                  if (canEditRow(row.status)) {
                    return [...data, row.id];
                  }

                  return data;
                }, []);

                const row = tableOption.data.find((data) =>
                  RESEND_STATUSES.includes(data.status)
                );

                if (row) {
                  const { status } = row;
                  setSelectedStatus(status);

                  const data = tableOption.data.filter(
                    (item) => item.status !== null && item.status === status
                  );

                  setResendData(data);
                }

                setCheckedItems(items);
              } else {
                setCheckedItems([]);
              }
            }}
          />
        </div>
      ),
      selector: (row) => row?.id,
      sortable: false,
      id: isExecutive ? "checkbox" : "checkbox-hidden",
      width: "60px",
      style: { paddingLeft: 0, paddingRight: 0 },
      // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
      cell: (row) => {
        return canEditRow(row.status) && isExecutive ? (
          <div className="w-100 p-0 d-flex justify-content-center align-items-center">
            <CustomCheckbox
              // eslint-disable-next-line consistent-return
              onChange={(evt) => {
                const { checked } = evt.target;

                if (checked) {
                  if (selectedStatus !== "" && row.status !== selectedStatus) {
                    const newItems = checkedItems.filter(
                      (item) => item !== row.id
                    );
                    setCheckedItems(newItems);

                    return Toast({
                      type: 4,
                      content: "All selected rows must have the same status",
                    });
                  }
                  setCheckedItems([...checkedItems, row.id]);
                  setSelectedStatus(row.status);
                  setResendData([...resendData, row]);
                  setResendType("");
                } else {
                  const newItems = checkedItems.filter(
                    (item) => item !== row.id
                  );

                  const data = resendData.filter(
                    (__data) => __data.id !== row.id
                  );
                  setCheckedItems(newItems);
                  if (newItems.length === 0) setSelectedStatus("");
                  setResendData(data);
                }
              }}
              checked={checkedItems.includes(row.id)}
            />
          </div>
        ) : null;
      },
    },
    {
      name: "",
      selector: (row) => row?.badge,
      sortable: false,
      width: "20px",
      id: "badge",
      style: role === "Director" ? { paddingLeft: 0, paddingRight: 0 } : null,
      // eslint-disable-next-line max-len
      // eslint-disable-next-line react/no-unstable-nested-components, consistent-return
      cell: ({ status }, index) => {
        if (status === "Pending" || status === "Requested") {
          return (
            <Badge
              className={`badge ${
                // eslint-disable-next-line no-nested-ternary
                status === "Pending"
                  ? "is-pending"
                  : status === "Requested"
                  ? "is-requested"
                  : ""
              }`}
              pill
            >
              <div>{index}</div>
            </Badge>
          );
        }
      },
    },
    {
      name: "ID",
      selector: (row) => row?.id,
      identifier: "id",
      sortable: true,
      minWidth: "max-content",
      center: true,
      style: { paddingLeft: 0, paddingRight: 0 },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <p
          className={`mb-0 ${
            row.status === "Pending" || row.status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {row.id}
        </p>
      ),
    },
    {
      name: "Recipient",
      selector: (row) => row?.recipient,
      identifier: "recipient",
      grow: 2,
      sortable: true,
      minWidth: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, recipient }, index) =>
        editingRowId !== index ? (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {`${recipient?.lastName}, ${recipient?.firstName}`}
          </p>
        ) : (
          <AsyncSelect
            className={`asyc-dropdown w-100 ${getStatusOptionsPosition()}`}
            classNamePrefix="select"
            placeholder=""
            styles={searchInputStyles}
            value={{
              // eslint-disable-next-line max-len
              label: `${recipient?.lastName}, ${recipient?.firstName}`,
              // eslint-disable-next-line max-len
              value: `${recipient?.lastName}, ${recipient?.firstName}`,
            }}
            onChange={(option) => {
              handleSearchRecepientOnChange(option, index);
            }}
            loadOptions={_.debounce((inputValue, callback) => {
              api({
                Method: "GET",
                Url: `/congressman?search=${inputValue}`,
                // eslint-disable-next-line consistent-return
                ResponseSuccessCallback: (response) => {
                  if (response && response?.data) {
                    const data =
                      response?.data?.length !== 0 ? response?.data : [];

                    callback(
                      data?.map((item) => ({
                        ...item,
                        label: item?.name,
                        value: item?.id,
                      }))
                    );
                  }
                },
                ResponseFailCallback: () => {
                  callback([]);
                },
              });
            }, 1000)}
          />
        ),
    },
    {
      name: "Budget",
      selector: (row) => row?.budget_allocation,
      identifier: "budget_allocation",
      grow: 2,
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components, camelcase
      cell: (row, index) =>
        editingRowId !== index ? (
          <p
            className={`mb-0 ${
              row.status === "Pending" || row.status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {`PHP ${Number(row.budget_allocation)?.toLocaleString("en-US")}`}
          </p>
        ) : (
          <FormGenertor
            className="form-control"
            type="number"
            onChange={(e) => {
              const { value } = e;

              const newData = [...tableOption.data];

              newData[index] = {
                ...row,
                // eslint-disable-next-line camelcase
                budget_allocation: Number(value),
              };

              setTableOption({
                ...tableOption,
                data: newData,
              });
            }}
            value={Number(row.budget_allocation)}
          />
        ),
    },
    {
      name: "Type",
      selector: (row) => row?.allocationType,
      identifier: "allocationType",
      sortable: true,
      minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, allocationType }) => {
        return (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {allocationType.id === 2 ? "ATC" : allocationType.name}
          </p>
        );
      },
    },
    {
      name: "Other Info",
      selector: (row) => row?.allocationType,
      identifier: "allocationType",
      sortable: true,
      minWidth: "170px",
      grow: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (
        {
          status,
          allocationType,
          allocationName,
          allocationContactName,
          institution,
          otherInfo,
        },
        index
      ) => {
        let renderOtherInfo = null;

        if (Number(id) === 3 && allocationType.id === 1) {
          renderOtherInfo = otherInfo || "";
        } else if (
          agencyWithSpecialForms.includes(Number(id)) &&
          allocationType.id === 1
        ) {
          renderOtherInfo = institution?.name;
        } else if (allocationType.id === 2) {
          renderOtherInfo = allocationName;
        } else if (allocationType.id === 3) {
          renderOtherInfo = allocationContactName;
        }

        const { isEllipsisActive, textRef } = isEllipsisUsed();

        return (
          <div>
            <p
              className={`m-0 other-info ${
                isEllipsisActive ? `truncated-${index}` : ""
              } ${
                status === "Pending" || status === "Requested"
                  ? "font-weight-bold is-pending"
                  : ""
              }`}
              ref={textRef}
              data-tip={isEllipsisActive ? renderOtherInfo : ""}
              data-for={`tooltip-${index}`}
            >
              {renderOtherInfo}
            </p>
            {isEllipsisActive && (
              <Tooltip
                id={`tooltip-${index}`}
                style={{
                  backgroundColor: "black",
                  maxWidth: "500px",
                  zIndex: "1000",
                }}
                effect="float"
                place="top"
                anchorSelect={`.truncated-${index}`}
                opacity={1}
              >
                {renderOtherInfo}
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      identifier: "status",
      grow: 2,
      sortable: true,
      minWidth: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status }, index) => (
        <CustomSelect
          className={`${
            status === "Pending" || status === "Requested" ? "is-pending" : ""
          } ${cannotEditStatus(status) ? "" : "pointer"}`}
          isSearchable={false}
          isDisabled={cannotEditStatus(status) || checkedItems.length !== 0}
          onChange={(option) => {
            handleStatusChange(index, option);
          }}
          value={statuses.find((stat) => stat.value === status)}
          options={getStatusOptions(status)}
          styles={statusSelectStyles}
          classNamePrefix="select"
          menuPlacement={getStatusOptionsPosition()}
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(option) => {
            return option?.displayLabel;
          }}
        />
      ),
    },
    {
      name: "Implementing Agency",
      selector: (row) => row?.implementing,
      identifier: "implementing",
      grow: 2,
      sortable: false,
      minWidth: "165px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => {
        return (
          <CustomSelect
            className={`region ${
              row.status === "Pending" || row.status === "Requested"
                ? "is-pending"
                : ""
            }`}
            isSearchable
            isDisabled={
              editingRowId !== index && hasPermission("recipient-update")
            }
            onChange={(option) => {
              const newData = [...tableOption.data];

              if (option.value === 19) {
                newData[index] = {
                  ...row,
                  receivingEntity: option?.label,
                  implementing: {
                    id: 19,
                    region: "Others",
                  },
                };
              } else {
                newData[index] = {
                  ...row,
                  implementing: {
                    id: option?.value,
                    region: option?.label,
                  },
                };
              }

              setTableOption({
                ...tableOption,
                data: newData,
              });
            }}
            value={regions.find(
              (region) => region.value === row.implementing?.id
            )}
            options={regions}
            styles={regionSelectStyles}
            classNamePrefix="select"
            components={{
              DropdownIndicator: SelectDropdownIndicator,
            }}
            menuPlacement={getStatusOptionsPosition()}
            maxMenuHeight="150px"
          />
        );
      },
    },
    {
      name: "Province",
      selector: (row) => row?.province,
      identifier: "province",
      id: "province",
      sortable: true,
      minWidth: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, province }) => (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {province?.name}
        </p>
      ),
    },
    {
      name: "Municipality",
      selector: (row) => row?.municipality,
      identifier: "municipality",
      id: "municipality",
      sortable: true,
      minWidth: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, municipality }) => (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {municipality?.name}
        </p>
      ),
    },
    {
      name: "Date Requested",
      selector: (row) => row?.dateRequested,
      identifier: "dateRequested",
      id: "dateRequested",
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ status, dateRequested }) => (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {getFormattedDateString(dateRequested, "MMM-dd-yyy")}
        </p>
      ),
    },
    {
      name: "Date Approved",
      selector: (row) => row?.dateApproved,
      identifier: "dateApproved",
      id: "dateApproved",
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ dateApproved, status }) =>
        // eslint-disable-next-line no-nested-ternary
        dateApproved ? (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {getFormattedDateString(dateApproved, "MMM-dd-yyyy")}
          </p>
        ) : (
          <p
            className={`mb-0 ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {"-"}
          </p>
        ),
    },
    {
      name: "Remarks",
      selector: (row) => row?.hasComments,
      sortable: false,
      minWidth: "160px",
      // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
      cell: ({ status, hasComments, id, unreadMsg }) => (
        <div
          className={`comment-section p-0 ${
            status === "Pending" || status === "Requested" ? "is-pending" : ""
          }`}
        >
          {hasComments === 1 && hasPermission("comment-view") && (
            <>
              {status === "Disapproved" && unreadMsg !== 0 && (
                <Badge
                  className="unread-msgs"
                  pill
                >
                  {unreadMsg === 0 ? null : unreadMsg}
                </Badge>
              )}
              <Button
                className="view-comment-btn"
                size="sm"
                onClick={() => {
                  setEditingCongressman(id);
                  fetchComments(id);
                  toggleModal();
                }}
              >
                {"View Comment"}
              </Button>
            </>
          )}

          {hasComments === 0 && hasPermission("comment-create") && (
            <Button
              className="add-comment-btn"
              size="sm"
              onClick={() => {
                setEditingCongressman(id);
                fetchComments(id);
                toggleModal();
              }}
            >
              {"Add Comment"}
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "Updated By",
      selector: (row) => row?.updatedBy,
      identifier: "updatedBy",
      id: "updatedBy",
      grow: 1,
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ updatedBy, status }) =>
        updatedBy && updatedBy !== null ? (
          <p className="mb-0">{updatedBy}</p>
        ) : (
          <p
            className={`mb-0  ${
              status === "Pending" || status === "Requested"
                ? "font-weight-bold is-pending"
                : ""
            }`}
          >
            {"-"}
          </p>
        ),
    },
    {
      name: "Actions",
      selector: (row) => row?.id,
      sortable: false,
      width: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => {
        const isBumpIconDisabled =
          timer && timer?.find((time) => time?.id === row.id && time?.isActive);

        return (
          <>
            {editingRowId !== index && canEditRow(row?.status) && (
              <>
                <Edit2
                  size={16}
                  id={`edit-row--${index}`}
                  color="#4252C9"
                  className="mr-05"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/projects/${id}/program/${programID}/recipient`, {
                      state: {
                        row,
                        agency: id,
                        program: selectedProject?.ID,
                        action: "edit",
                      },
                    });
                  }}
                />
                <UncontrolledTooltip
                  placement="left"
                  target={`edit-row--${index}`}
                >
                  {"Edit"}
                </UncontrolledTooltip>
              </>
            )}

            <Eye
              size={16}
              color="#4252C9"
              className="mr-05"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/projects/${id}/program/${programID}/recipient`, {
                  state: {
                    row,
                    agency: id,
                    program: selectedProject?.ID,
                    action: "view",
                  },
                })
              }
              id={`view-row--${index}`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`view-row--${index}`}
            >
              {"View"}
            </UncontrolledTooltip>

            {resendIconStatus.includes(row.status) && isExecutive && (
              <>
                <RotateCcw
                  size={16}
                  className="mr-05"
                  id={`resend-row--${index}`}
                  color="#4252C9"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsResendModalShown(true);
                    setResendData([row]);
                    setResendType("single");
                    setMessageType("approval");
                  }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`resend-row--${index}`}
                >
                  {"Resend"}
                </UncontrolledTooltip>
              </>
            )}

            {allowedBumpStatus.includes(row.status) && (
              <>
                <FontAwesomeIcon
                  id={`bump-row--${index}`}
                  icon={faCommentDollar}
                  color="#4252C9"
                  size="xl"
                  className="mr-05"
                  style={
                    !isBumpIconDisabled
                      ? { cursor: "pointer" }
                      : { cursor: "initial", pointerEvents: "none" }
                  }
                  disabled={isBumpIconDisabled}
                  onClick={() => handleBumpIconOnClicked(row.id, row.status)}
                />

                <UncontrolledTooltip
                  placement="top"
                  target={`bump-row--${index}`}
                >
                  {"Bump"}
                </UncontrolledTooltip>
              </>
            )}

            {editingRowId !== index && hasPermission("recipient-delete") && (
              <>
                <Trash2
                  size={16}
                  id={`delete-row--${index}`}
                  onClick={() => handleDeleteAction(row)}
                  color="#4252C9"
                  style={{ cursor: "pointer" }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`delete-row--${index}`}
                >
                  {"Delete"}
                </UncontrolledTooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    toggleResendModal();
    setResendData([]);
    setCheckedItems([]);
    setSelectedStatus("");
  };

  const handleShowResendModal = () => {
    if (resendData.length !== 0) {
      if (checkedItems.length !== resendData.length) {
        const resendIDs = resendData.map((data) => data.id);

        const items = checkedItems.filter((item) => resendIDs.includes(item));
        setCheckedItems(items);

        Toast({
          type: 3,
          content: "All selected rows must have the same status.",
        });
      }

      setResendType("multiple");
      toggleResendModal();
    } else {
      Toast({
        type: 4,
        content: "Invalid selected rows.",
      });
      setCheckedItems([]);
    }
  };

  const fundSourceColumn = {
    name: "Fund Source",
    selector: (row) => row?.fundSource,
    identifier: "fundSource",
    sortable: true,
    minWidth: "150px",
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ status, fundSource }) => {
      return (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {fundSource}
        </p>
      );
    },
  };

  const editorNotesColumn = {
    name: "Editor Notes",
    selector: (row) => row?.editorNotes,
    identifier: "editorNotes",
    sortable: true,
    minWidth: "150px",
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ status, editorNotes }) => {
      return (
        <p
          className={`mb-0 ${
            status === "Pending" || status === "Requested"
              ? "font-weight-bold is-pending"
              : ""
          }`}
        >
          {editorNotes
            ? editorNotes
                ?.split(";")
                ?.map((notes) => notes)
                ?.join(", ")
            : ""}
        </p>
      );
    },
  };

  if (
    (Number(id) === 14 || (Number(id) === 3 && selectedProject?.ID === 14)) &&
    role &&
    (isExecutive || role === "Agency")
  ) {
    columns.splice(10, 0, fundSourceColumn);
  }

  if (role && isExecutive) {
    columns.splice(12, 0, editorNotesColumn);
  }

  const hasProgramAccess = (progId) => {
    genericGet({
      url: `auth/has-access?program=${progId}`,
      callback: (response) => {
        if (!response.data) navigate("/404");
      },
    });
  };

  useEffect(() => {
    hasProgramAccess(programID);
  }, [programID]);

  useEffect(() => {
    fetchAgency();
    fetchRegions();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    programID,
    programsRowPerPage,
    tableOption.offset,
    tableOption.searchValue,
    tableOption.sort.direction,
    tableOption.sort.field,
    selectedStatusFilter,
  ]);

  useEffect(() => {
    if (selectOptions?.length !== 0) {
      // eslint-disable-next-line array-callback-return
      selectOptions?.map((op, index) => {
        if (op?.ID === Number(programID)) {
          setSelectedProject(selectOptions[index]);
        }
      });
    }
  }, [selectOptions]);

  useEffect(() => {
    if (selectedProject !== null) {
      fetchBudgetAllocation();
    }
  }, [selectedProject]);

  useEffect(() => {
    fetchData();

    if (selectedProject) fetchBudgetAllocation();
  }, [dateFrom]);

  return (
    <div className="p-0 m-0 program-details">
      <Row className="d-flex justify-content-end mt-5 mx-5">
        <Col
          xs="auto"
          className="custom-breadcrumb--comtainer"
        >
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Programs",
                redirect: "/projects",
              },
              {
                name: agencyDetails?.name,
                redirect: `/projects/${agencyDetails?.id}`,
              },
              {
                name: selectedProject?.Name,
                isActive: true,
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 mx-5 d-flex align-items-center select-container">
        <Col>
          <Row className="d-flex align-items-center">
            <Col
              xs="auto"
              className="p-0 project-logo"
            >
              {/* agency logo here */}
              <ImgContainer
                src={agencyDetails?.photo}
                height="110px"
              />
            </Col>

            <Col
              xs="12"
              sm="8"
              md="6"
              lg="6"
              xl="6"
            >
              {/* agency select dropwdown here */}
              <CustomSelect
                isLoading={tableOption.isLoading}
                isDisabled={tableOption.isLoading}
                onChange={(option) => {
                  setSelectedProject(option);
                  navigate(`/projects/${id}/program/${option?.ID}`);
                }}
                value={selectedProject}
                options={selectOptions}
                styles={customProjectStyles}
                components={{
                  DropdownIndicator,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className="p-0 d-flex mx-5 row-section"
        style={{ flexWrap: "nowrap" }}
      >
        <Col xs="auto" />

        <Col
          xs="12"
          md="10"
          lg="10"
          xl="11"
        >
          <div className="budget-container">
            <div className="d-flex justify-content-between align-items-center p-0">
              <Col className="pl-0">
                <h5 className="font-weight-bold">{"Budget Allocation"}</h5>
              </Col>
              <Col>
                <div className="budget-allocation">
                  <div className="d-flex flex-row">
                    {!isBudgetEditing && (
                      <Edit2
                        size={16}
                        onClick={() => toggle()}
                        className="mr-1"
                        color="#4252C9"
                      />
                    )}
                    <h6>{"Total Budget"}</h6>
                  </div>
                  <h6 className="font-weight-bold">
                    {getFormattedNumber(totalBudget)}
                  </h6>
                </div>
                <BudgetComponent
                  value={totalBudget}
                  newValue={newTotalBudget}
                  isOpen={isBudgetEditing}
                  handleToggle={toggle}
                  handleOnChange={handleOverallBudgetChange}
                  totalAmountSpent={totalAmountSpent}
                />
              </Col>
            </div>

            <div className="p-0 mb-3 mt-2">
              <StackedProgressBar data={projectBudget} />
            </div>

            <div className="p-0">
              <div className="pl-0 d-flex flex-row">
                <h6>{"Amount Spent: \r"}</h6>
                <h6 className="font-weight-bold ml-25">
                  {getFormattedNumber(totalAmountSpent)}
                </h6>
              </div>
            </div>
            <div className="p-0">
              <div className="pl-0 d-flex flex-row">
                <h6>{"Remaining Balance: \r"}</h6>
                <h6 className="font-weight-bold ml-25">
                  {getFormattedNumber(remainingBalance)}
                </h6>
              </div>
            </div>
          </div>
          <Row
            className="p-0 d-flex search-status-row"
            style={{ flexWrap: "nowrap" }}
          >
            <Col
              lg="2"
              className="d-flex align-items-center justify-content-start d-none d-lg-flex search-bar"
            >
              {/* searchbar here */}
              <div className="position-relative">
                <SearchBarComponent
                  onChangeSearch={onChangeSearch}
                  isDisabled={tableOption?.isLoading}
                />
              </div>
            </Col>
            {isExecutive && (
              <Col
                lg="4"
                className="d-flex align-items-center justify-content-center d-none d-lg-flex status-filter"
              >
                <CustomSelect
                  placeholder="Status"
                  name="statusFilter"
                  styles={statusFilterStyle}
                  classNamePrefix="select"
                  onChange={handleChangeFilter}
                  value={selectedStatusFilter}
                  components={{ DropdownIndicator }}
                  options={statusFilter}
                  isMulti
                  isSearchable={false}
                  hideSelectedOptions={false}
                />
              </Col>
            )}
          </Row>

          {checkedItems.length !== 0 && (
            <Row>
              <Col
                sm="6"
                md="4"
                xl="2"
                className="my-2"
              >
                <CustomSelect
                  isSearchable={false}
                  onChange={(option) => {
                    handleBatchUpdate(option);
                  }}
                  placeholder="Status"
                  options={getStatusOptions()}
                  styles={batchStatusStyle}
                  classNamePrefix="select"
                  getOptionLabel={(option) => {
                    return option?.displayLabel;
                  }}
                />
              </Col>

              <Col
                sm="6"
                md="4"
                xl="2"
                className="d-flex align-items-center"
              >
                <CustomSelect
                  isSearchable={false}
                  onChange={(option) => {
                    setMessageType(option.value);
                    handleShowResendModal();
                  }}
                  placeholder="Resend"
                  options={resendOptions}
                  styles={batchStatusStyle}
                  classNamePrefix="select"
                />
              </Col>

              <Col
                sm="6"
                md="4"
                xl="2"
                className="my-2 align-items-center"
              >
                <Button
                  className="delete-multiple-btn d-sm-flex d-xl-flex"
                  onClick={() => {
                    handleMultipleDelete();
                  }}
                >
                  <Trash2 className="mr-25" />
                  <p className="mb-0 ml-25">{"Delete Selected"}</p>
                </Button>
              </Col>
            </Row>
          )}

          {/* table here */}
          <Card className="">
            <CustomDataTable
              columns={columns}
              data={tableOption.data}
              noHeader
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onSort={onSort}
              paginationPerPage={programsRowPerPage}
              paginationServer
              paginationTotalRows={tableOption.totalRows}
              progressPending={tableOption.isLoading}
              sortServer
              defaultSortField={tableOption.sort.field}
              defaultSortAsc={tableOption.sort.direction}
            />
          </Card>
        </Col>
      </Row>
      <CommentsModal
        isOpen={isModalShown}
        handleClose={toggleModal}
        comments={comments}
        congID={editingCongressman}
        fetchComments={fetchComments}
        programID={programID}
        handleFetchTable={fetchData}
      />
      <ResendModal
        isOpen={isResendModalShown}
        data={resendData}
        handleClose={handleCloseModal}
        type={resendType}
        messageType={messageType}
      />
    </div>
  );
};

export default Main;
