import React, { memo, useState } from "react";
import {
  Button,
  CardText,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Toast } from "utility/helpers";
import PropTypes from "prop-types";
import { genericPost, genericPut } from "services/services.general";

import "react-bootstrap-tagsinput/dist/index.css";
import "./styles.scss";

const ResendModal = ({
  isOpen,
  event,
  handleClose,
  fetchComments,
  fetchDataTable,
  eventName,
  eventId,
  view,
}) => {
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    if (!reason.trim()) {
      return Toast({
        type: 4,
        content: "Reason cannot be empty.",
      });
    }

    const newStatus = "Declined";
    const data = event;

    data.eventParticipantStatus = newStatus;

    await genericPost({
      url: "/comment",
      formData: {
        text: reason,
        recipientId: null,
        newsId: null,
        eventId,
      },
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          setReason("");
          if (view === "rep") fetchComments(eventId);
          setIsLoading(false);
        }
      },
    });

    await genericPut({
      url: `/eventsparticipant/${data.id}`,
      formData: {
        declineReason: reason,
        eventParticipantStatus: newStatus,
      },
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          setReason("");
          fetchDataTable();
          setIsLoading(false);
        }
      },
    });

    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      centered
      className="decline-modal"
    >
      <ModalHeader toggle={handleClose}>{"Resend Message"}</ModalHeader>
      <ModalBody>
        <div className="mx-2">
          <div className="decline-modal--container">
            <div className="mt-2">
              <CardText style={{ textAlign: "center" }}>
                {`Kindly indicate your reason to decline 
                  the invitation at ${eventName}`}
              </CardText>
            </div>
            <div className="msg-body">
              <textarea
                rows={5}
                value={reason}
                disabled={isLoading}
                onChange={(e) => {
                  const { value } = e.target;

                  setReason(value);
                }}
                placeholder="Reason to decline"
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-2 d-flex justify-content-center">
            <Button
              className="btn-save"
              onClick={() => handleSubmit()}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ResendModal.prototype = {
  isOpen: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.string]),
  handleClose: PropTypes.func,
  type: PropTypes.string,
  messageType: PropTypes.string,
};

export default memo(ResendModal);
