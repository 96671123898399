import React, { useEffect, useState } from "react";
import { UncontrolledTooltip, Button } from "reactstrap";
import { useNavigate } from "react-router";

import Swal from "sweetalert2";
import { Edit2, Eye, Plus, RotateCcw, Trash2 } from "react-feather";

import BreadCrumbComponent from "components/customs/breadCrumb";
import SearchBarComponent from "components/customs/searchbarV2";
import CustomDataTable from "components/customs/table";
import { defaultTableOption } from "utility/constants";
import {
  Toast,
  formatTime,
  getFormattedDateString,
  hasPermission,
} from "utility/helpers";
import {
  genericDelete,
  genericGet,
  genericPost,
} from "services/services.general";
import CommentsModal from "components/customs/comments-modal";

import {
  HeaderTextSection,
  BreadCrumbSection,
  MainContainer,
  Container,
  AddButton,
  TableSection,
  StyledDiv,
  CommentsContainer,
  ViewCommentBtn,
  AddCommentBtn,
  StyledLink,
  StyledButtonGroup,
} from "./styled";

const EventsList = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [activeView, setActiveView] = useState("Event");
  const [isModalShown, setIsModalShown] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [comments, setComments] = useState([]);

  const toggleModal = () => setIsModalShown(!isModalShown);

  const navigate = useNavigate();

  let changeSearchTimeoutID;

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 10,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  // eslint-disable-next-line consistent-return
  const handleViewChange = (eventView) => {
    setActiveView(eventView);

    setTableOption({
      ...tableOption,
      isLoading: true,
    });
  };

  const fetchDataTable = async () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    await genericGet({
      url: activeView === "Event" ? "/events" : "/events/congressman",
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          data: data.rows,
          totalRows: data.count,
          isLoading: false,
        });
      },
    });
  };

  const handleDeleteAction = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to delete event ${row?.eventName}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        genericDelete({
          url: `/events/${row.id}`,
          callback: ({ isSuccess }) => {
            setTableOption({
              ...tableOption,
              isLoading: false,
            });

            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfull deleted action.",
              });
              fetchDataTable();
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const fetchComments = (id) => {
    genericGet({
      url: `/events/${id}/comments`,
      callback: (data) => {
        setComments(data);
      },
    });
  };

  const handleResendClicked = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to resend ${row.eventName}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        genericPost({
          url: "/events/resend",
          formData: {
            eventId: row.id.toString(),
          },
          callback: ({ isSuccess, msg }) => {
            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully resend sms.",
              });

              navigate(0);
            } else {
              Toast({
                type: 4,
                content: msg,
              });
            }
          },
        });
      }
    });
  };

  const eventColumns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      style: { paddingLeft: 0, paddingRight: 0 },
      cell: () => null,
    },
    {
      name: "Event Name",
      selector: ({ eventName }) => eventName,
      identifier: "eventName",
      grow: 4,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventName }) => <p className="mb-0 wrap-text">{eventName}</p>,
    },
    {
      name: "Date Period",
      selector: ({ eventStartDate }) => eventStartDate,
      identifier: "datePeriod",
      grow: 2,
      sortable: true,
      width: "210px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventStartDate, eventEndDate }) => (
        <p className="mb-0 wrap-text">
          {getFormattedDateString(eventStartDate, "MMM dd, yyyy")}
          {" - "}
          {getFormattedDateString(eventEndDate, "MMM dd, yyyy")}
        </p>
      ),
    },
    {
      name: "Time",
      selector: ({ eventStartTime }) => eventStartTime,
      identifier: "time",
      grow: 1,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventStartTime, eventEndTime }) => (
        <p className="mb-0 wrap-text">
          {formatTime(eventStartTime)}
          {" - "}
          {formatTime(eventEndTime)}
        </p>
      ),
      width: "170px",
    },
    {
      name: "Venue",
      selector: ({ eventVenue }) => eventVenue,
      identifier: "venue",
      grow: 3,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventVenue }) => <p className="mb-0 wrap-text">{eventVenue}</p>,
    },
    {
      name: "Invitees",
      selector: ({ totalParticipants }) => totalParticipants,
      identifier: "invitees",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ totalParticipants }) => (
        <p className="mb-0 wrap-text">{totalParticipants}</p>
      ),
    },
    {
      name: "Confirmed",
      selector: ({ numberOfConfirmed }) => numberOfConfirmed,
      identifier: "confirmed",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ numberOfConfirmed }) => (
        <p className="mb-0 wrap-text">{numberOfConfirmed}</p>
      ),
    },
    {
      name: "Declined",
      selector: ({ numberOfDeclined }) => numberOfDeclined,
      identifier: "declined",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ numberOfDeclined }) => (
        <p className="mb-0 wrap-text">{numberOfDeclined}</p>
      ),
    },
    {
      name: "No Response",
      selector: ({ numberOfNoResponse }) => numberOfNoResponse,
      identifier: "noResponse",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ numberOfNoResponse }) => (
        <p className="mb-0 wrap-text">{numberOfNoResponse}</p>
      ),
    },
    {
      name: "RSVP Date",
      selector: ({ rsvpStartDate }) => rsvpStartDate,
      identifier: "rsvpDate",
      grow: 2,
      sortable: true,
      width: "210px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ rsvpStartDate, rsvpEndDate }) => (
        <p className="mb-0 wrap-text">
          {getFormattedDateString(rsvpStartDate, "MMM dd, yyyy")}
          {" - "}
          {getFormattedDateString(rsvpEndDate, "MMM dd, yyyy")}
        </p>
      ),
    },
    {
      name: "Remarks",
      selector: ({ remarks }) => remarks,
      identifier: "remarks",
      grow: 2,
      width: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ hasComments, id }) => (
        <CommentsContainer>
          {hasComments ? (
            <ViewCommentBtn
              size="sm"
              onClick={() => {
                fetchComments(id);
                setCommentId(id);
                toggleModal();
              }}
            >
              {"View Comment"}
            </ViewCommentBtn>
          ) : (
            <AddCommentBtn
              size="sm"
              onClick={() => {
                setCommentId(id);
                toggleModal();
              }}
            >
              {"Add Comment"}
            </AddCommentBtn>
          )}
        </CommentsContainer>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row?.id,
      sortable: false,
      width: "120px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => (
        <>
          {hasPermission("event-view") && (
            <>
              <Eye
                size={16}
                id={`view-row--${index}`}
                onClick={() => {
                  navigate(`/events/${row.id}/details`, {
                    state: { event: row },
                  });
                }}
                className="mr-05"
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="auto"
                target={`view-row--${index}`}
              >
                {"View"}
              </UncontrolledTooltip>
            </>
          )}

          <RotateCcw
            size={16}
            color="#4252C9"
            className="mr-05"
            onClick={() => handleResendClicked(row)}
            id={`resend-row--${row.id}`}
          />
          <UncontrolledTooltip
            placement="right"
            target={`resend-row--${row.id}`}
          >
            {"Resend"}
          </UncontrolledTooltip>

          {hasPermission("event-update") && (
            <>
              <Edit2
                size={16}
                id={`edit-row--${index}`}
                onClick={() => {
                  navigate("/events/edit", {
                    state: { row, action: "edit" },
                  });
                }}
                className="mr-05"
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="auto"
                target={`edit-row--${index}`}
              >
                {"Edit"}
              </UncontrolledTooltip>
            </>
          )}

          {hasPermission("event-delete") && (
            <>
              <Trash2
                size={16}
                id={`delete-row--${index}`}
                onClick={() => handleDeleteAction(row)}
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="right"
                target={`delete-row--${index}`}
              >
                {"Delete"}
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
  ];

  const congEventsColumns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      style: { paddingLeft: 0, paddingRight: 0 },
      cell: () => null,
    },
    {
      name: "Member Name",
      selector: (record) => record["Congressman.fullName"],
      identifier: "region",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (record) => (
        <p className="mb-0 wrap-text">{record["Congressman.fullName"]}</p>
      ),
    },
    {
      name: "Position",
      selector: ({ position }) => position,
      identifier: "position",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ position }) => (
        <p className="mb-0 wrap-text">{position || ""}</p>
      ),
    },
    {
      name: "Events",
      selector: ({ eventCount }) => eventCount,
      identifier: "eventCount",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ eventCount }) => <p className="mb-0 wrap-text">{eventCount}</p>,
    },
    {
      name: "Remarks",
      selector: ({ remarks }) => remarks,
      identifier: "remarks",
      grow: 2,
      width: "170px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: null,
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      // width: "120px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) =>
        hasPermission("event-update") && (
          <>
            <Eye
              size={16}
              id={`view-row--${index}`}
              onClick={() => {
                navigate(`/events/congressman/${row.congressmanId}/events`, {
                  state: {
                    row,
                  },
                });
              }}
              className="mr-05"
              color="#4252C9"
            />
            <UncontrolledTooltip
              placement="auto"
              target={`view-row--${index}`}
            >
              {"View"}
            </UncontrolledTooltip>
          </>
        ),
    },
  ];

  useEffect(() => {
    fetchDataTable();
  }, [
    activeView,
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
  ]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Events",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>
        <HeaderTextSection>
          <h2 className="text-danger">{"Events"}</h2>
        </HeaderTextSection>

        <StyledDiv>
          <StyledButtonGroup>
            <Button
              color={activeView === "Event" ? "primary" : "secondary"}
              className={`view-toggle-btn ${
                activeView === "Event" ? "view-active" : ""
              }`}
              onClick={() => handleViewChange("Event")}
            >
              {"BY EVENT"}
            </Button>
            <Button
              color={activeView === "CongCos" ? "primary" : "secondary"}
              className={`view-toggle-btn ${
                activeView === "CongCos" ? "view-active" : ""
              }`}
              onClick={() => handleViewChange("CongCos")}
            >
              {"BY CONG/COS"}
            </Button>
          </StyledButtonGroup>
          <div>
            {activeView === "CongCos" ? (
              <SearchBarComponent
                placeholder="Search Cong/COS..."
                onChangeSearch={onChangeSearch}
                isDisabled={tableOption?.isLoading}
              />
            ) : (
              activeView === "Event" &&
              hasPermission("event-create") && (
                <StyledLink
                  to="/events/add"
                  state={{ action: "add" }}
                >
                  <AddButton>
                    <Plus className="mr-25" />
                    <p className="mb-0">{"Add an Event"}</p>
                  </AddButton>
                </StyledLink>
              )
            )}
          </div>
        </StyledDiv>

        <TableSection>
          <CustomDataTable
            columns={activeView === "Event" ? eventColumns : congEventsColumns}
            data={tableOption.data}
            noHeader
            progressPending={tableOption.isLoading}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={tableOption.currentRowsPerPage}
            paginationServer
            paginationTotalRows={tableOption.totalRows}
            height="calc(100vh - 200px)"
          />
        </TableSection>
      </Container>
      <CommentsModal
        isOpen={isModalShown}
        handleClose={toggleModal}
        comments={comments}
        fetchComments={fetchComments}
        congID={commentId}
        view="events"
        handleFetchTable={fetchDataTable}
      />
    </MainContainer>
  );
};

export default EventsList;
